<template>
  <div class="flex justify-center">
    <div class="flex flex-col w-full items-center">
      <div
        class="flex flex-col w-full md:w-[650px] lg:w-[1000px] items-start gap-6">
        <div
          class="flex w-full flex-col md:items-center lg:self-stretch text-center">
          <div class="text-4xl lg:text-6xl">Passportal</div>
          <div class="text-xl text-gray-700 mt-2">
            {{ i18n.t("We match tickets specifically to your passport") }}
          </div>
        </div>
        <TravelMap class="item" />
        <Locations
          ref="locationsElement"
          class="container-widget item"
          :no-button="!pageStore.firstSearch && !pageStore.search.suggestedBy"
          @submit="findRoutes" />
        <div class="container-widget item text-center p-4">
          <div class="text-xl">{{ i18n.t("Popular destinations") }}</div>
          <PopularDestinations
            class="mt-4 lg:mx-20"
            @submit="pageStore.search.destination = $event" />
        </div>
        <div class="w-full lg:self-stretch container-widget flex flex-col">
          <Documents ref="documentsElement" />
          <button class="m-4 mt-0 lg:self-stretch" @click="findRoutes">
            {{ i18n.t("Search") }}
          </button>
        </div>
        <Result
          v-if="pageStore.lastSearch && !showLoader"
          :show-loader="!showLoader"
          ref="routesElement" />
      </div>
      <Stars class="mt-10" />
    </div>
    <Overlay v-model="visaWarning">
      <VisaWarning
        :passport="sift(globalStore.documents)[0].issuer.countryCode!"
        :destination="pageStore.search.destination!"
        @add-visa="addVisa"
        @another-passport="visaWarningAnotherPassport"
        @skip="visaWarningSkip" />
    </Overlay>
    <Overlay v-model="passportWarning">
      <PassportWarning
        @close="passportWarning = false"
        @select="
          !(passportWarning = false) &&
            documentsElement?.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
        " />
    </Overlay>
    <Overlay
      :model-value="rateLimitAlert != 0"
      @update:model-value="
        $event ? (rateLimitAlert = rateLimitAlert) : (rateLimitAlert = 0)
      ">
      <RateLimitAlert :status-code="rateLimitAlert" />
    </Overlay>
    <Overlay
      :model-value="!!ukraineTransitWarning"
      @update:model-value="ukraineTransitWarning = undefined">
      <UkraineTransitWarningComponent
        :side="ukraineTransitWarning!"
        @close="ukraineTransitWarning = undefined" />
    </Overlay>
    <Overlay
      :model-value="!!russiaTransitWarning"
      @update:model-value="russiaTransitWarning = undefined">
      <russiaTransitWarningComponent
        :side="russiaTransitWarning!"
        :is-belarus="belorusiaTransitWarning"
        @close="russiaTransitWarning = undefined" />
    </Overlay>
    <Loader v-if="showLoader" />
    <router-view v-slot="{ Component }">
      <Overlay :model-value="!!Component" route>
        <component :is="Component" />
      </Overlay>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, reactive, ref, watch } from "vue";
import {
  DocumentDto,
  DocumentInput,
  DocumentType,
  Location,
  routerFindRoutes,
  routerGetLocation,
  routerResults,
  userDocumentsGetDefaultDocuments,
  visaCheckCheckSingle
} from "../api/client";
import Documents from "./documents/Documents.vue";
import Locations from "./locations/Locations.vue";
import Loader from "../components/Loader.vue";
import PopularDestinations from "./PopularDestinations.vue";
import Overlay from "../components/overlay/Overlay.vue";
import VisaWarning from "./VisaWarning.vue";
import PassportWarning from "./PassportWarning.vue";
import RateLimitAlert from "./RateLimitAlert.vue";
import Stars from "./stars/Stars.vue";
import UkraineTransitWarningComponent from "./UkraineTransitWarning.vue";
import russiaTransitWarningComponent from "./russiaTransitWarning.vue";
import { handle200 } from "../api";
import { sift, sleep } from "radash";
import { fetchSuggestions, makeSuggestion } from "./locations/common";
import { useEmitterEvent, useGeoIP } from "../common";
import {
  documentIssuers,
  documentTypes,
  passportType,
  visaType
} from "./documents/common";
import { addDays, secondsToMilliseconds, startOfDay } from "date-fns";
import { postEvent } from "../analytics";
import { RouteExt, useMainPageEmitter, useMainPageStore } from "./store";
import { useGlobalStore } from "../store";
import { useRoute, useRouter } from "vue-router";
import { HttpError, ok } from "oazapfts";
import Result from "./result/Result.vue";
import { whenever } from "@vueuse/core";
import cuid2 from "@paralleldrive/cuid2";
import { useI18n } from "../locale";
import TravelMap from "../travel_map/TravelMap.vue";

const globalStore = useGlobalStore();
const pageStore = useMainPageStore();
const pageEmitter = useMainPageEmitter();
const router = useRouter();
const route = useRoute();

const showLoader = ref(false);
const i18n = useI18n();

const passportWarning = ref(false);
const ukraineTransitWarning = ref<"from" | "to">();
const russiaTransitWarning = ref<"from" | "to">();
const belorusiaTransitWarning = ref(false);

const rateLimitAlert = ref(0);

const locationsElement = ref<InstanceType<typeof Locations>>();
const documentsElement = ref<InstanceType<typeof Documents>>();
const routesElement = ref<InstanceType<typeof Result>>();

const visaWarning = ref(false);

pageStore.search.date = addDays(new Date(), 7);

useEmitterEvent(pageEmitter, "scrollToDocuments", () => {
  documentsElement.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "center"
  });
});
useEmitterEvent(pageEmitter, "scrollToLocations", () => {
  locationsElement.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "center"
  });
});

whenever(useGeoIP, async location => {
  const resp = await fetchSuggestions(location.city ?? location.country);
  if (!pageStore.search.origin)
    pageStore.search.origin =
      resp.find(l => l.cityName == location.city) ??
      resp.find(l => l.countryCode == location.countryCode);
});

whenever(
  () => documentTypes.value.length && documentIssuers.value.length,
  async () => {
    if (route.query.search) {
      try {
        const search = JSON.parse(atob(route.query.search as string));
        const origin = makeSuggestion(
          await ok(routerGetLocation(search.origin))
        );
        const destination = makeSuggestion(
          await ok(routerGetLocation(search.destination))
        );
        const date = new Date(search.date);

        const documents: DocumentDto[] = (
          search.documents as DocumentInput[]
        ).map(d => {
          return {
            type: documentTypes.value.find(t => t.id == d.type)!,
            issuer: documentIssuers.value.find(i => i.id == d.issuer)!
          };
        });

        pageStore.search.origin = origin;
        pageStore.search.destination = destination;
        pageStore.search.date = date;
        globalStore.documents = documents.map(d => ({
          ...d,
          id: cuid2.createId()
        }));
        pageStore.showDocuments = true;
      } catch (err) {
        console.error(err);
      }
    }
  }
);

async function findRoutes() {
  const originValue = pageStore.search.origin;
  const destinationValue = pageStore.search.destination;
  const dateValue = pageStore.search.date;
  if (!originValue || !destinationValue || !dateValue) {
    locationsElement.value!.$el.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    return;
  }

  gtag("event", "find_routes", {
    origin: originValue.code,
    originCountry: originValue.countryCode,
    destination: destinationValue.code,
    destinationCountry: destinationValue.countryCode
  });
  showLoader.value = true;

  const noDocuments = sift(globalStore.documents).length == 0;
  const firstSearch = pageStore.firstSearch;

  let documents = sift(globalStore.documents);

  try {
    pageStore.lastSearch = undefined;

    belorusiaTransitWarning.value = false;
    switch (pageStore.search.origin?.countryCode) {
      case "UA":
        ukraineTransitWarning.value = "from";
        return;
      case "RU":
        russiaTransitWarning.value = "from";
        return;
      case "BY":
        russiaTransitWarning.value = "from";
        belorusiaTransitWarning.value = true;
        return;
    }

    switch (pageStore.search.destination?.countryCode) {
      case "UA":
        ukraineTransitWarning.value = "to";
        return;
      case "RU":
        russiaTransitWarning.value = "to";
        return;
      case "BY":
        russiaTransitWarning.value = "to";
        belorusiaTransitWarning.value = true;
        return;
    }

    showLoader.value = true;
    if (noDocuments) {
      pageStore.searchInProgress = true;
      const resp = await handle200(userDocumentsGetDefaultDocuments());
      documents = globalStore.documents = [
        { ...resp.data, id: cuid2.createId() }
      ];
    }

    if (!pageStore.firstSearch) {
      passportWarning.value = !documents.find(
        d => d.type.id == passportType.value?.id
      );
      if (passportWarning.value) return;
    }

    pageStore.searchInProgress = true;

    const hasVisa = documents.length > 1;
    const countryShown = Boolean(
      pageStore.visaWarning.lastUniqCountryWarning[destinationValue.countryCode]
    );
    const visaWarningSpam =
      Object.keys(pageStore.visaWarning.lastUniqCountryWarning).length > 2;

    if (!(hasVisa || countryShown || visaWarningSpam)) {
      const resp = await handle200(
        visaCheckCheckSingle({
          origin: originValue.id,
          destination: destinationValue.id,
          departureDate: dateValue.toISOString(),
          documents: documents.map(d => {
            return {
              issuer: d.issuer.id,
              type: d.type.id
            };
          })
        })
      );

      if (resp.data.isGood.find(g => !g) != undefined) {
        visaWarning.value = true;
        pageStore.visaWarning.lastUniqCountryWarning[
          destinationValue.countryCode
        ] = new Date();
        return;
      }
    }

    const req = {
      origin: originValue.id,
      destination: destinationValue.id,
      documents: sift(globalStore.documents).map(d => {
        return {
          issuer: d.issuer.id,
          type: d.type.id
        };
      }),
      date: dateValue.toISOString(),
      suggestedBy: pageStore.search.suggestedBy,
      isFirst: firstSearch
    };

    postEvent("search.started");
    const resp = await handle200(routerFindRoutes(req));

    const reqId = resp.data.id;
    const routes: RouteExt[] = reactive([]);
    pageStore.lastSearch = {
      date: dateValue,
      origin: originValue,
      destination: destinationValue,
      documents,
      req,
      first: firstSearch,
      routes,
      reqId,
      error: false
    };

    while (true) {
      await sleep(secondsToMilliseconds(5));
      const resp = await handle200(routerResults(reqId));
      if (showLoader.value && resp.data.routes.length > 0) {
        showLoader.value = false;
        await nextTick();
        routesElement.value!.$el.scrollIntoView({ behavior: "smooth" });
      }
      routes.push(
        ...resp.data.routes.map(r => {
          return {
            ...r,
            id: cuid2.createId(),
            segments: r.segments.map(sg => ({
              ...sg,
              segments: sg.segments.map(s => ({ ...s, id: cuid2.createId() }))
            }))
          };
        })
      );
      if (resp.data.isComplete) break;
    }

    postEvent("search.finished");
    pageStore.firstSearch = false;
    pageStore.signInAlert.searchCount++;

    if (
      (startOfDay(new Date()).getTime() -
        startOfDay(new Date(pageStore.signInAlert.lastShown)).getTime()) /
        1000 /
        60 /
        60 /
        24 >=
      1
    ) {
      pageStore.signInAlert.searchCount = 1;
      pageStore.signInAlert.lastShown = new Date();
    }

    if (
      !globalStore.user &&
      (pageStore.signInAlert.searchCount == 2 ||
        pageStore.signInAlert.searchCount == 6)
    ) {
      await sleep(1000);
      if (route.name != "index") {
        let stop = () => {};
        stop = watch(route, route => {
          if (route.name == "index") {
            router.push("/index/suggestLogin");
            pageStore.signInAlert.lastShown = new Date();
            postEvent("signin.suggested", { reqId });
            stop();
          }
        });
      } else {
        router.push("/index/suggestLogin");
        pageStore.signInAlert.lastShown = new Date();
        postEvent("signin.suggested", { reqId });
      }
    }
  } catch (e) {
    postEvent("search.error");
    console.error(e);
    if (e instanceof HttpError) {
      rateLimitAlert.value = e.status;
    } else {
      alert("Unknown error. Please contact us in Telegram");
    }
    if (pageStore.lastSearch) pageStore.lastSearch.error = true;
  } finally {
    pageStore.searchInProgress = false;
    showLoader.value = false;
  }
}

async function addVisa(destination: Location) {
  visaWarning.value = false;
  globalStore.documents.push({
    type: visaType.value! satisfies DocumentType,
    issuer: documentIssuers.value.find(
      i => i.countryCode == destination.countryCode
    )!,
    id: cuid2.createId()
  });
  findRoutes();
}

function visaWarningAnotherPassport() {
  visaWarning.value = false;
  documentsElement.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "center"
  });
}

function visaWarningSkip() {
  visaWarning.value = false;
  findRoutes();
}
</script>

<style lang="sass" scoped>
.item
  @apply w-full
</style>
