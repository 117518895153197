<template>
  <div
    class="flex flex-row p-3 gap-3"
    @click="$router.push('/story/' + story.id)">
    <Votes @click.stop :story="story" />

    <div class="flex flex-col grow gap-3">
      <div>
        <h1 class="w-0 min-w-full text-lg font-bold truncate cursor-pointer">
          {{ story.title }}
        </h1>
        <div class="flex flex-row w-full">
          <div class="w-0 grow truncate">
            {{ story.body }}
          </div>
          <div class="font-bold text-blue-500 cursor-pointer">read more</div>
        </div>
      </div>

      <StoryHeader :story="story" />
      <div class="text-sm flex items-center">
        <span class="font-bold">
          {{
            story.author?.firstName
              ? story.author.firstName + " " + (story.author.lastName ?? "")
              : i18n.t("Anonymous")
          }}
        </span>
        <span class="ml-3">
          {{
            story.createdAt.toLocaleDateString(store.locale, {
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          }}
        </span>
        <div
          class="flex flex-row items-center gap-1 hover:text-blue-500 cursor-pointer"
          @click.stop="shareModal = true">
          <icon class="ml-3 p-2" icon="share" />{{ i18n.t("Share") }}
        </div>
      </div>
    </div>
    <Overlay v-model="shareModal" @click.stop>
      <StoryShareModal :id="story.id" @close="shareModal = false" />
    </Overlay>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Story } from "../../api/gql/graphql";
import Overlay from "../../components/overlay/Overlay.vue";
import StoryShareModal from "./StoryShareModal.vue";
import { useGlobalStore } from "../../store";
import StoryHeader from "./StoryHeader.vue";
import Votes from "./Votes.vue";
import { i18n } from "@lingui/core";

defineProps<{
  story: Story;
}>();
const shareModal = ref(false);
const store = useGlobalStore();
</script>

<style lang="sass" scoped>
.flag-shadow
  @apply drop-shadow rounded-md h-7 w-7
</style>
