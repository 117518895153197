<template>
  <div>
    <div>
      {{ i18n.t("Sorry, there are no tickets available for your route") }}
    </div>
    <div>
      {{
        bothWereAlreadyCapitals
          ? i18n.t("Please search another one")
          : i18n.t(
              "Please try searching from more popular locations in those countries"
            )
      }}
    </div>
    <div
      v-if="!bothWereAlreadyCapitals"
      class="flex flex-col md:flex-row gap-x-20 gap-y-10 justify-center mt-10">
      <div v-if="originCapital" class="relative border rounded">
        <label class="absolute -top-7 left-0 font-bold">{{
          i18n.t("Suggested From")
        }}</label>
        <div
          class="suggest-button"
          :class="{
            'grayscale brightness-200': isOrigAlreadyCapital,
            'cursor-pointer': !isOrigAlreadyCapital
          }"
          @click="suggestOrigin">
          <Flag class="h-5" :country-code="originCapital.countryCode" />
          <span>{{ originCapital.countryName }}</span>
          <span v-if="originCapital.cityName">|</span>
          <span v-if="originCapital.cityName">{{
            originCapital.cityName
          }}</span>
        </div>
      </div>
      <div v-if="destCapital" class="relative border rounded">
        <label class="absolute -top-7 left-0 font-bold">{{
          i18n.t("Suggested To")
        }}</label>
        <div
          class="suggest-button"
          :class="{
            'grayscale brightness-200': isDestAlreadyCapital,
            'cursor-pointer': !isDestAlreadyCapital
          }"
          @click="suggestDest">
          <Flag class="h-5" :country-code="destCapital.countryCode" />
          <span>{{ destCapital.countryName }}</span>
          <span v-if="destCapital.cityName">|</span>
          <span v-if="destCapital.cityName">{{ destCapital.cityName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Flag from "../../components/Flag.vue";
import airports from "../country_to_airport.json";
import { makeSuggestion } from "../locations/common";
import { computed } from "vue";
import { useMainPageEmitter, useMainPageStore } from "../store";
import { isEqual } from "radash";
import { Location } from "../../api/client";
import { useI18n } from "../../locale";

const mainPageStore = useMainPageStore();
const i18n = useI18n();

const originCapital = computed(() => {
  const capital = airports[
    mainPageStore.lastSearch!.origin.countryCode as keyof typeof airports
  ] as Location | undefined;

  if (capital) return makeSuggestion(capital);

  return undefined;
});
const isOrigAlreadyCapital = computed(() =>
  isEqual(mainPageStore.search.origin, originCapital.value)
);

const destCapital = computed(() => {
  const capital = airports[
    mainPageStore.lastSearch!.destination.countryCode as keyof typeof airports
  ] as Location | undefined;

  if (capital) return makeSuggestion(capital);

  return undefined;
});
const isDestAlreadyCapital = computed(() =>
  isEqual(mainPageStore.search.destination, destCapital.value)
);

const bothWereAlreadyCapitals = computed(
  () =>
    isEqual(mainPageStore.lastSearch?.origin, originCapital.value) &&
    isEqual(mainPageStore.lastSearch?.destination, destCapital.value)
);

const emitter = useMainPageEmitter();

function suggestOrigin() {
  const isAlready = isOrigAlreadyCapital.value;
  mainPageStore.search.origin = originCapital.value;
  if (!isAlready) {
    emitter.emit("scrollToLocations");
    emitter.emit("highlightLocations", "from");
  }
  mainPageStore.search.suggestedBy = [
    mainPageStore.lastSearch?.origin.code!,
    mainPageStore.lastSearch?.destination.code!
  ];
}

function suggestDest() {
  const isAlready = isDestAlreadyCapital.value;
  mainPageStore.search.destination = destCapital.value;
  if (!isAlready) {
    emitter.emit("scrollToLocations");
    emitter.emit("highlightLocations", "to");
  }
  mainPageStore.search.suggestedBy = [
    mainPageStore.lastSearch?.origin.code!,
    mainPageStore.lastSearch?.destination.code!
  ];
}
</script>

<style lang="sass" scoped>
.suggest-button
  @apply flex flex-row items-center gap-1 md:gap-2 p-1 px-2 truncate
</style>
