import { computed, watch } from "vue";
import { Suggestion } from "../../components/Autocomplete.vue";
import { handle200 } from "../../api";
import {
  DocumentIssuer,
  DocumentType,
  userDocumentsListDocumentIssuers,
  userDocumentsListDocumentTypes
} from "../../api/client";
import { computedAsync } from "@vueuse/core";
import { useGlobalStore } from "../../store";
import { sift } from "radash";
import { useI18n } from "../../locale";

export function getPassportImgUrl(countryCode: string) {
  return `/passports/full/${countryCode.toLowerCase()}.png`;
}

const SCHENGEN_CODES = [
  "AT",
  "BE",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IS",
  "IT",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "HR"
];

export type TypeSuggestion = DocumentType & Suggestion;
export type IssuerSuggestion = DocumentIssuer & Suggestion;

export function makeSuggestion<T extends { name: string }>(
  obj: T
): T & Suggestion {
  return {
    ...obj,
    text: obj.name
  };
}

export const documentTypes = computedAsync(
  async () => {
    const resp = await handle200(userDocumentsListDocumentTypes());
    return resp.data.map(makeSuggestion);
  },
  [],
  { lazy: true }
);

export const documentIssuers = computedAsync(
  async () => {
    const resp = await handle200(userDocumentsListDocumentIssuers());
    const i18n = useI18n();
    return resp.data.map(v => {
      const isSc = SCHENGEN_CODES.includes(v.countryCode!);
      return {
        ...v,
        text: v.name + (isSc ? ` (${i18n.t("Schengen")})` : ""),
        secondCountryCode: isSc ? "EU" : null
      };
    });
  },
  [],
  { lazy: true }
);

export const passportType = computed(() =>
  documentTypes.value.find(t => t.canonicalName == "Passport")
);
export const visaType = computed(() =>
  documentTypes.value.find(t => t.canonicalName == "Visa")
);

watch(documentTypes, types => {
  const store = useGlobalStore();
  for (const doc of sift(store.documents)) {
    const type = types.find(t => t.id == doc.type.id);
    if (type) doc.type = type;
  }
});

watch(documentIssuers, issuers => {
  const store = useGlobalStore();
  for (const doc of sift(store.documents)) {
    const issuer = issuers.find(i => i.id == doc.issuer.id);
    if (issuer) doc.issuer = issuer;
  }
});
