<template>
  <div ref="el">
    <StoriesPage
      v-for="pageIdx in pageCount"
      ref="pages"
      :key="pageIdx"
      :created-before="createdBefore"
      :page-idx="pageIdx - 1" />
    <Loader v-if="fetching && pageCount == 1" />
    <div
      v-if="fetching && pageCount != 1"
      class="flex flex-row self-stretch justify-center">
      <div class="w-20 h-max" v-html="loaderSvg"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onActivated, onDeactivated, ref, toRefs } from "vue";
import { useScroll, watchDebounced } from "@vueuse/core";
import StoriesPage from "./StoriesPage.vue";
import loaderSvg from "../../components/logo-animated.svg?raw";
import Loader from "../../components/Loader.vue";
import { last, sort } from "radash";

const createdBefore = new Date();

const pages = ref([] as InstanceType<typeof StoriesPage>[]);
const pageCount = ref(1);

const fetching = computed(() => pages.value.some(p => p.fetching));

const el = ref();
const { arrivedState } = useScroll(document.body);
const { bottom } = toRefs(arrivedState);
const isActive = ref(false);
onActivated(() => (isActive.value = true));
onDeactivated(() => (isActive.value = false));

watchDebounced(
  bottom,
  bottom => {
    const lastPage = last(sort(pages.value, p => p.idx));
    if (isActive.value && bottom && lastPage && !lastPage.isEnd) {
      pageCount.value++;
    }
  },
  { debounce: 300 }
);
</script>

<style scoped></style>
