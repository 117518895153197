<template>
  <div class="text-center">
    <div>
      <p>
        {{ i18n.t("If you encounter any problems on the site, contact us") }}:
        <a href="mailto:help@passportal.org" target="_blank">
          help@passportal.org
        </a>
      </p>
    </div>
    <div
      class="flex flex-wrap self-center p-5 gap-3 items-start justify-center">
      <a
        class="flex flex-row gap-x-3 items-center rounded-xl border border-gray-300 color-reset px-4 h-[69px] py-auto w-80"
        href="https://twitter.com/llcpassportal"
        target="_blank">
        <img class="h-12 w-12" src="/favicon.svg" alt="A" />
        <div class="flex flex-col items-start leading-4">
          <div class="font-bold">Passportal</div>
          <div class="font-bold text-gray-500">@llcpassportal</div>
        </div>
        <div class="grow"></div>
        <div class="rounded-2xl border border-gray-300 h-min p-1 px-3">
          Follow
        </div>
        <svg class="h-7 w-7" viewBox="0 0 24 24" aria-hidden="true">
          <g>
            <path
              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
          </g>
        </svg>
      </a>
      <a
        class="w-80"
        href="https://www.producthunt.com/posts/passportal?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-passportal"
        target="_blank">
        <img
          class="w-full"
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=419371&theme=light"
          alt="Passportal - Find&#0032;flights&#0032;for&#0032;your&#0032;passport&#0032;and&#0032;visa&#0032;situation | Product Hunt" />
      </a>
      <a
        class="flex flex-row gap-x-3 items-center rounded-xl border border-gray-300 color-reset px-4 h-[69px] py-auto w-80"
        href="https://www.linkedin.com/in/nasaok"
        target="_blank">
        <img alt="A" class="h-12 w-12 rounded-full" src="./tsvietkov.jpg" />
        <div class="flex flex-col items-start leading-4">
          <div class="font-bold">Mykola Tsvietkov</div>
          <div class="font-bold text-gray-500">@nasaok</div>
        </div>
        <div class="rounded-2xl border border-gray-300 h-min p-1 px-3">
          Connect
        </div>
        <svg
          class="h-8"
          height="72"
          viewBox="0 0 72 72"
          width="72"
          xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
              fill="#007EBB" />
            <path
              d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
              fill="#FFF" />
          </g>
        </svg>
      </a>
    </div>
    <div class="not-first:ml-2 mt-7">
      <a :href="config.links.tos" target="_blank"> Terms of use </a>
      <a :href="config.links.pp" target="_blank"> Privacy policy </a>
      <span>|</span>
      <a :href="config.links.tosUA" target="_blank"> Умови використання </a>
      <a :href="config.links.ppUA" target="_blank">
        Політика конфіденційності
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "../locale";

const i18n = useI18n();

const config = window.config;
</script>
