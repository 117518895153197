<template>
  <div class="flex justify-center mt-10">
    <div
      class="flex flex-col items-center gap-6 w-full lg:w-auto lg:min-w-[800px]">
      <Stories class="container-widget bg-white self-stretch" />
      <router-link
        class="font-bold button sticky bottom-5 box-shadow shadow-xl text-3xl text-white text-center z-[2]"
        style="padding: 1rem !important; height: auto !important"
        to="/stories/shareStory">
        {{ i18n.t("Share your story") }}
      </router-link>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive include="ShareStory, ShareStorySuccess">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import Stories from "./story/Stories.vue";
import { useI18n } from "../locale";

const i18n = useI18n();
</script>

<style scoped></style>
