<template>
  <div class="flex flex-row items-center gap-3 flex-wrap">
    <div class="flex flex-row gap-3 items-center">
      <div class="font-bold min-w-max order-first h-7 relative">
        <Hover
          v-slot="{ isHovered }"
          :disable="md"
          class="flex flex-row gap-2 items-center min-w-max">
          <div class="flex gap-x-2 whitespace-nowrap">
            <div class="w-7 h-7">
              <Flag
                :country-code="story.stops.at(0)?.country.code ?? ''"
                class="flag-shadow" />
            </div>
            <div v-if="md || isHovered">
              {{ story.stops.at(0)?.country.name }}
            </div>
          </div>
        </Hover>
      </div>

      <div class="font-bold min-w-max order-last h-7 relative">
        <Hover
          v-slot="{ isHovered }"
          :disable="md"
          class="flex flex-row gap-2 items-center min-w-max">
          <div class="flex gap-x-2">
            <div class="w-7 h-7">
              <Flag
                :country-code="story.stops.at(-1)?.country.code ?? ''"
                class="flag-shadow" />
            </div>
            <div v-if="md || isHovered">
              {{ story.stops.at(-1)?.country.name }}
            </div>
          </div>
        </Hover>
      </div>

      <div
        v-if="story.stops.length > 3"
        class="flex flex-row items-center gap-2">
        <icon icon="plane" />
        <Hover v-slot="{ isHovered }">
          <div
            :style="
              isHovered
                ? {}
                : { width: `${1.3 + 0.5 * (story.stops.length - 2)}rem` }
            ">
            <div
              ref="transitCountriesEl"
              :class="{
                'relative h-7 cursor-pointer': !isHovered,
                'flex flex-col gap-2 z-1 w-max': isHovered
              }"
              class="flex transition-all">
              <div v-if="isHovered" class="font-bold">
                {{ i18n.t("Transits") }}
              </div>
              <div
                v-for="(stop, idx) in story.stops.slice(1, -1)"
                class="flex items-center gap-2">
                <div class="h-7">
                  <Flag
                    :class="{
                      absolute: !isHovered
                    }"
                    :country-code="stop.country.code!"
                    :style="{ left: `${0.5 * idx}rem` }"
                    class="h-7 flag-shadow" />
                </div>
                <div v-if="isHovered">{{ stop.country.name }}</div>
              </div>
            </div>
          </div>
        </Hover>
        <icon icon="plane" />
      </div>
      <div
        v-else-if="story.stops.length == 3"
        class="flex flex-row items-center font-bold gap-2">
        <Flag
          :country-code="story.stops[1].country.code!"
          class="h-7 flag-shadow" />
        {{ story.stops[1].country.name }}
      </div>
      <icon v-else icon="plane" />
    </div>

    <div
      class="flex flex-row gap-2 md:order-last grow items-center justify-end">
      <icon icon="plane" />
      <div>
        {{
          new Date(
            story.date.year,
            story.date.month ?? 1,
            story.date.day ?? 1
          ).toLocaleDateString(store.locale, {
            month: md ? "long" : "short",
            year: "numeric"
          })
        }}
      </div>
    </div>

    <div class="relative w-full md:w-auto h-7">
      <Hover v-slot="{ isHovered }">
        <div>
          <div v-if="!isHovered" class="flex flex-row gap-2">
            <div
              v-if="passportDocument"
              class="flex flex-row items-center gap-2">
              <icon icon="passport" class="h-7" />
              {{ passportDocument?.issuer.name }}
              <Flag
                v-show="isHovered"
                :country-code="passportDocument.issuer.countryCode!"
                class="h-7 w-7" />
            </div>
            <div
              v-if="visaDocument"
              class="flex flex-row items-center gap-2 w-max">
              <img src="/visa.png" class="h-7" />
              {{ passportDocument?.issuer.name }}
              <Flag
                v-show="isHovered"
                :country-code="visaDocument.issuer.countryCode!"
                class="h-7 w-7" />
            </div>
            <div
              v-if="
                (passportDocument ? 1 : 0) + (visaDocument ? 1 : 0) <
                story.documents.length
              "
              class="flex flex-row items-center gap-1">
              <icon class="h-7" icon="file-lines" />
              <span>{{ i18n.t("+more") }}</span>
            </div>
          </div>
          <div v-else class="flex flex-col items-start gap-2">
            <div
              v-for="doc in story.documents"
              class="flex flex-row items-center gap-2 w-max">
              <img
                v-if="doc.type.canonicalName == 'Visa'"
                src="/visa.png"
                class="h-7" />
              <icon v-else class="h-7" :icon="getIconForDocument(doc)" />
              <span v-if="isHovered">{{ doc.type.name }}:</span>
              <span v-if="isHovered">{{ doc.issuer.name }}</span>
              <Flag
                v-show="isHovered"
                :country-code="doc.issuer.countryCode!"
                class="h-7 w-7" />
            </div>
          </div>
        </div>
      </Hover>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "../../locale";
import { DocumentDto, Story } from "../../api/gql/graphql";
import { useGlobalStore } from "../../store";
import { computed } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import Flag from "../../components/Flag.vue";
import Hover from "./Hover.vue";

const props = defineProps<{
  story: Story;
}>();

const i18n = useI18n();
const store = useGlobalStore();

const md = useBreakpoints(breakpointsTailwind).greater("sm");
const passportDocument = computed(() =>
  props.story.documents.find(d => d.type.canonicalName == "Passport")
);
const visaDocument = computed(() =>
  props.story.documents.find(d => d.type.canonicalName == "Visa")
);

function getIconForDocument(doc: DocumentDto): string {
  if (doc.type.canonicalName == "Passport") return "passport";

  return "file-lines";
}
</script>

<style scoped></style>
