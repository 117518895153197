<template>
  <StoryCard
    v-for="story of res.data.value?.stories ?? []"
    :story="story"
    class="hover:bg-gray-100" />
</template>

<script lang="ts" setup>
import { useQuery } from "@urql/vue";
import { graphql } from "../../api/gql";
import StoryCard from "./StoryCard.vue";
import { useI18n } from "../../locale";
import { toRef } from "vue";

const limit = 10;

const props = defineProps<{
  pageIdx: number;
  createdBefore: Date;
}>();

defineExpose({
  get isEnd() {
    return (
      !res.fetching.value &&
      res.data.value &&
      res.data.value.stories.length < limit
    );
  },
  get fetching() {
    return res.fetching;
  },
  get idx() {
    return props.pageIdx;
  }
});

const i18n = useI18n();

const variables = {
  offset: props.pageIdx * limit,
  limit: limit,
  createdBefore: props.createdBefore
};
const res = useQuery({
  query: graphql(`
    query GetStories($offset: Int!, $limit: Int!, $createdBefore: DateTime) {
      stories(offset: $offset, limit: $limit, createdBefore: $createdBefore) {
        id
        title
        body
        createdAt
        slug
        author {
          id
          firstName
          lastName
        }
        documents {
          type {
            id
            name
            canonicalName
          }
          issuer {
            id
            name
            canonicalName
            countryCode
          }
        }
        stops {
          country {
            id
            name
            code
          }
        }
        votesCount
        myVote
        date {
          year
          month
          day
        }
      }
    }
  `),
  variables,
  context: {
    locale: toRef(() => i18n.locale)
  }
});
</script>

<style scoped></style>
