<template>
  <div
    class="container-widget w-full max-h-full lg:w-[70%] lg:max-h-[95%] overflow-y-auto bg-white p-1 py-5 md:p-10">
    <div class="flex flex-col gap-y-7">
      <div class="flex flex-col xl:flex-row gap-y-4">
        <div class="xl:order-1 xl:w-1/3 px-10 flex flex-col">
          <div
            class="flex flex-row justify-center items-center gap-x-2 max-w-full">
            <icon
              :class="{ invisible: !(selectedPassport > 0) }"
              class="fa-xl p-2 cursor-pointer"
              icon="fa fa-chevron-left fa-lg"
              @click="selectedPassport -= 1" />
            <div class="h-72 xl:h-auto">
              <img
                class="max-h-full"
                v-if="!showFlagInstead"
                :src="getPassportImgUrl(passports[selectedPassport])"
                @error="showFlagInstead = true" />
              <Flag
                class="max-h-full"
                :country-code="passports[selectedPassport]"
                v-else />
            </div>
            <icon
              :class="{
                invisible: !(
                  passports && selectedPassport < passports.length - 1
                )
              }"
              class="fa-xl p-2 cursor-pointer"
              icon="fa fa-chevron-right"
              @click="selectedPassport += 1" />
          </div>
          <div class="flex flex-col text-center gap-y-4 mt-7 items-center grow">
            <div class="text-lg font-bold">
              {{
                i18n.t("The route is crafted specificaly for your documents.")
              }}
            </div>
            <button class="bg-orange-300 w-[90%]" @click="notMyDocuments()">
              {{ i18n.t("Not your passport?") }}
            </button>
            <div
              v-if="!isMobile"
              class="flex items-center px-5 gap-2 border rounded-md p-7 w-full">
              <img
                class="h-8"
                src="https://images.kiwi.com/common/kiwicom-logo.svg"
                alt="" />
              <div class="font-extrabold">
                <Price :amount="route.price" />
              </div>
              <div class="grow"></div>
              <button
                v-if="buyableSegments.length > 1"
                :href="(buyModal = true)"
                class="buy-button"
                @click="clickedBuy">
                <div>{{ i18n.t("Buy") }}</div>
              </button>
              <a
                v-else
                :href="buyableSegments[0].url"
                target="_blank"
                class="buy-button"
                @click="clickedBuy">
                <div>{{ i18n.t("Buy") }}</div>
              </a>
            </div>
            <button
              class="fancy-gradient text-white font-bold text-center cursor-pointer flex flex-row items-center gap-3 p-2 px-5"
              @click="cryptoSurvey = true">
              {{ i18n.t("Buy with crypto") }}
              <icon icon="fa-brands fa-bitcoin" />
              <icon icon="fa-brands fa-ethereum" />
              <icon icon="fa-brands fa-monero" />
            </button>
            <div
              v-if="isXl"
              class="flex flex-row text-base gap-x-1 items-center mt-auto relative">
              <div>{{ i18n.t("Is this information incorrect?") }}</div>
              <button :class="{ 'bg-green-300': reported }" @click="report">
                <div v-if="reported">
                  <div>{{ i18n.t("Reported.") }}</div>
                  <div>{{ i18n.t("Thank you!") }}</div>
                </div>
                <div v-else>{{ i18n.t("Let us know") }}</div>
              </button>
            </div>
          </div>
        </div>
        <div class="xl:w-2/3 pt-3 xl:pt-0">
          <div class="flex flex-row items-center gap-x-2 font-bold">
            <div>{{ route.segments[0].segments[0].from.name }}</div>
            <icon icon="fa-minus" />
            <div>
              {{ route.segments.at(-1)!.segments.at(-1)!.to.name }}
            </div>
          </div>
          <div class="text-md text-gray-500">
            {{ i18n.t("Travel time:") }} {{ formatTime(route.duration) }}
          </div>
          <RelatedStories
            v-if="route.stories"
            :stories="route.stories"
            class="mt-3 px-1"
            @close="scroll.y.value = 0" />
          <div v-for="group in groups" class="flex flex-col gap-y-5 mt-5">
            <div class="flex flex-col w-full">
              <RouteSegment
                v-for="seg in group.segments"
                :segment="seg"
                :group-kind="group.group.kind" />
            </div>
            <div
              v-if="!isXl"
              class="flex flex-row text-base gap-x-1 justify-center items-center mt-auto">
              <div>{{ i18n.t("Is this information incorrect?") }}</div>
              <button @click="$emit('incorrectInfo')">
                {{ i18n.t("Let us know.") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Map :route="route" class="shrink-0 h-[30rem]" />
      <button
        class="block bg-gray-300 w-full"
        :class="{ 'mb-20': isMobile }"
        @click="$router.back()">
        {{ i18n.t("Close") }}
      </button>
    </div>
    <Overlay v-model="buyModal">
      <div class="flex flex-col gap-y-4 bg-white p-10 rounded-lg items-end">
        <div class="text-left text-xl flex flex-col items-start">
          <div>
            {{ i18n.t("There are") }} {{ route.segments.length }}
            {{ i18n.t("separate tickets in this flight.") }}
          </div>
          <div>{{ i18n.t("Buy them separately.") }}</div>
          <div>
            {{ i18n.t("Please open them both now to not lose the ticket.") }}
          </div>
          <a
            v-for="group of route.segments"
            :href="group.url"
            class="buy-button mt-4"
            target="_blank"
            @click="clickedBuy">
            From {{ group.segments[0].from.name }} to
            {{ group.segments.at(-1)?.to.name }}
          </a>
        </div>
        <button @click="buyModal = false">{{ i18n.t("Close") }}</button>
      </div>
    </Overlay>
    <Overlay
      :model-value="Boolean(visaSurvey)"
      class="z-[1001]"
      @update:model-value="show => !show && (visaSurvey = undefined)">
      <VisaSurvey
        v-if="visaSurvey"
        :destination="visaSurvey"
        @close="visaSurvey = undefined" />
    </Overlay>
    <Overlay v-model="cryptoSurvey" class="z-[1001]">
      <CryptoSurvey
        class="max-h-screen overflow-y-auto"
        @close="cryptoSurvey = false" />
    </Overlay>
    <div
      v-if="isMobile"
      class="fixed z-[1000] bottom-0 left-0 w-screen bg-white h-20 border rounded-t-2xl flex items-center px-5 gap-5"
      style="box-shadow: 0 0 15px 0 rgb(0 0 0 / 0.5)">
      <img
        class="h-8"
        src="https://images.kiwi.com/common/kiwicom-logo.svg"
        alt="" />
      <div class="font-extrabold">
        <Price :amount="route.price" />
      </div>
      <div class="grow"></div>
      <button
        v-if="buyableSegments.length > 1"
        :href="(buyModal = true)"
        class="buy-button"
        @click="clickedBuy">
        <div>{{ i18n.t("Buy") }}</div>
      </button>
      <a
        v-else
        :href="buyableSegments[0].url"
        target="_blank"
        class="buy-button"
        @click="clickedBuy">
        <div>{{ i18n.t("Buy") }}</div>
      </a>
      <div
        class="fancy-gradient px-3 rounded text-white font-bold text-center cursor-pointer"
        @click="cryptoSurvey = true">
        {{ i18n.t("Buy") }}
        <div class="flex flex-row pb-1 gap-1">
          <icon icon="fa-brands fa-bitcoin" />
          <icon icon="fa-brands fa-ethereum" />
          <icon icon="fa-brands fa-monero" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { sift } from "radash";
import { computed, onUnmounted, ref, watch } from "vue";
import { Location, routerReportIncorrectInfo } from "../../api/client";
import { postEvent } from "../../analytics";
import Overlay from "../../components/overlay/Overlay.vue";
import { getPassportImgUrl, passportType } from "../documents/common";
import Flag from "../../components/Flag.vue";
import VisaSurvey from "./VisaSurvey.vue";
import CryptoSurvey from "./CryptoSurvey.vue";
import {
  breakpointsTailwind,
  useBreakpoints,
  useCurrentElement,
  useScroll
} from "@vueuse/core";
import {
  type SegmentExt,
  useMainPageEmitter,
  useMainPageStore
} from "../store";
import { ok } from "oazapfts";
import { useRouter } from "vue-router";
import Map from "./map/Map.vue";
import { klona } from "klona";
import Price from "../../components/currency/Price.vue";
import { useI18n } from "../../locale";
import RouteSegment, { Layover } from "./RouteSegment.vue";
import RelatedStories from "./RelatedStories.vue";

const props = defineProps<{
  id: string;
}>();

const router = useRouter();

const mainPageStore = useMainPageStore();
const mainPageEmitter = useMainPageEmitter();
const route = mainPageStore.lastSearch!.routes.find(r => r.id == props.id)!;
const i18n = useI18n();

const expandTs = ref();
const buyModal = ref(false);
const buyModalTs = ref();
const buyModalCloseTs = ref();
const selectedPassport = ref(0);
const showFlagInstead = ref(false);
const reported = ref(false);
const visaSurvey = ref<Location>();
const cryptoSurvey = ref(false);
const scroll = useScroll(useCurrentElement() as any);

const isXl = useBreakpoints(breakpointsTailwind).greaterOrEqual("xl");
const isMobile = useBreakpoints(breakpointsTailwind).smaller("xl");

const buyableSegments = computed(() => {
  return route.segments.filter(sg => sg.url);
});

const passports = computed(() => {
  return sift(
    mainPageStore
      .lastSearch!.documents.filter(d => d.type.id == passportType.value?.id)
      .map(p => p.issuer.countryCode)
  );
});

const groups = computed(() => {
  return route.segments.map(group => {
    return {
      group,
      segments: group.segments.reduce<(SegmentExt & Layover)[]>((acc, cur) => {
        if (acc.length > 0) {
          const departure = cur.departure;
          const arrival = acc[acc.length - 1].arrival;

          acc[acc.length - 1].layover =
            (new Date(departure).getTime() - new Date(arrival).getTime()) /
            1000;
        }

        return [...acc, cur];
      }, [])
    };
  });
});

postEvent("ticket.summary.open");
expandTs.value = new Date().getTime();

onUnmounted(() => {
  postEvent("ticket.summary.close", {
    time: new Date().getTime() - expandTs.value
  });
});

watch(buyModal, v => {
  if (v) {
    postEvent("ticket.summary.click.buy", {
      time: new Date().getTime() - expandTs.value
    });
    if (buyModalCloseTs.value != undefined) {
      postEvent("ticket.summary.buyModal.open.second", {
        time: new Date().getTime() - (buyModalCloseTs.value ?? expandTs.value)
      });
    } else {
      postEvent("ticket.summary.buyModal.open", {
        time: new Date().getTime() - expandTs.value
      });
    }
    buyModalTs.value = new Date().getTime();
  } else {
    postEvent("ticket.summary.buyModal.close", {
      time: new Date().getTime() - buyModalTs.value
    });
    buyModalCloseTs.value = new Date().getTime();
  }
});

function clickedBuy() {
  const routeData = klona(route);
  routeData.segments.forEach(s =>
    s.segments.forEach(s => (s.check ? (s.check.messages = []) : undefined))
  );

  postEvent("ticket.summary.click.buy", {
    time: new Date().getTime() - expandTs.value,
    reqId: mainPageStore.lastSearch!.reqId,
    route: routeData
  });
}

function notMyDocuments() {
  router.back();
  mainPageEmitter.emit("scrollToDocuments");
  postEvent("ticket.summary.buyModal.close.notMydocuments", {
    time: new Date().getTime() - expandTs.value
  });
}

function formatTime(duration: number) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.round((duration / 60) % 60);
  return `${hours ? hours + "h " : ""}${minutes}m`;
}

async function report() {
  if (reported.value) return;

  try {
    await ok(
      routerReportIncorrectInfo({
        request: mainPageStore.lastSearch!.req,
        response: {
          routes: mainPageStore.lastSearch!.routes,
          isComplete: false
        },
        incorrectRoute: route
      })
    );
  } catch (e) {
    console.error(e);
  }
  reported.value = true;
}
</script>

<style lang="sass" scoped>
.buy-button
  @apply bg-blue-400 text-white font-extrabold py-2 rounded-md w-28

.buy-button > div
  @apply text-white text-center

label, label > input
  @apply cursor-pointer

label
  @apply flex items-center gap-3
</style>
