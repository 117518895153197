<template>
  <div
    class="w-full md:w-2/5 container-widget bg-white p-7 flex flex-col gap-y-5 text-center">
    <div>
      <div class="text-lg font-bold">
        <span v-if="props.side == 'from'">
          <p v-if="!isBelarus">
            {{ i18n.t("We do not work with Russian companies") }}
          </p>
          <p v-else>
            {{ i18n.t("We do not work with Belarusian companies.") }}
          </p>
        </span>
        <span v-else>{{
          i18n.t("We do not support people going to Russia or Belarus.")
        }}</span>
      </div>
      <div v-if="side == 'from'" class="text-lg">
        <p>
          {{ i18n.t("Please choose any neighbouring country, exit") }}
          {{ isBelarus ? i18n.t("Belarus") : i18n.t("Russia") }}
          {{ i18n.t("and find a flight.") }}
        </p>
        <p v-if="isBelarus">Жыве Беларусь!</p>
      </div>
      <div v-else-if="!isBelarus">
        {{
          i18n.t(
            "Russia is a terrorist state, that is killing Ukrainians right now."
          )
        }}
        <a
          href="https://mfa.gov.ua/en/10-facts-you-should-know-about-russian-military-aggression-against-ukraine"
          target="_blank">
          {{ i18n.t("Read more") }}
        </a>
        {{
          i18n.t(
            "If you are a Russian citizen, we strongly advise you to keep living abroad, your family and friends want to see you alive, not dead, participating in Russian invasion of Ukraine. Think of your parents and children, do not come back until the war is over, or else you will be sent to die for nothing by the Russian government."
          )
        }}
        <a
          href="https://twitter.com/search?q=%23RussiaisATerroistState&src=typeahead_click">
          #RussiaIsATerroristState
        </a>
      </div>
    </div>
    <PopularDestinations
      v-if="side == 'from'"
      :destinations="transits"
      @submit="
        event => {
          props.side == 'from'
            ? (pageStore.search.origin = event)
            : (pageStore.search.destination = event);
          pageEmitter.emit('highlightLocations', props.side);
          emit('close');
        }
      " />
    <button class="w-full bg-gray-300" @click="emit('close')">Close</button>
  </div>
</template>

<script lang="ts" setup>
import { Location, routerGetLocation } from "../api/client";
import PopularDestinations from "./PopularDestinations.vue";
import { useMainPageEmitter, useMainPageStore } from "./store";
import { useI18n } from "../locale";
import { computedAsync } from "@vueuse/core";
import { ok } from "oazapfts";

const props = defineProps<{
  side: "from" | "to";
  isBelarus?: boolean;
}>();
const emit = defineEmits<{
  (e: "close"): void;
}>();
const i18n = useI18n();

const pageStore = useMainPageStore();
const pageEmitter = useMainPageEmitter();

const transits = computedAsync<Location[]>(
  () =>
    Promise.all([
      ok(routerGetLocation("istanbul_tr")),
      ok(routerGetLocation("tbilisi_ge")),
      ok(routerGetLocation("bishkek_kg"))
    ]),
  []
);
</script>

<style scoped></style>
