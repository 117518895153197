import { authSignin } from "../api/client";
import { handle } from "../api";
import { useGlobalStore } from "../store";

export const SsoServices = ["google"] as const;
export type SsoService = (typeof SsoServices)[number];

function getSigninUrl(service: SsoService, redirectUri: string) {
  return (
    config.apiUrl +
    `/auth/signin/${service}/redirect?redirect_uri=${encodeURIComponent(
      redirectUri
    )}`
  );
}

export async function ssoSignin(service: SsoService) {
  const redirectUri = new URL("/signin", window.location.origin);
  const signinUrl = getSigninUrl(service, redirectUri.href);

  const popup = window.open(signinUrl, "_blank");
  if (popup == null) throw new Error("failed to open popup");

  let messageHandler;
  let closeWatch;
  try {
    return await new Promise<{ code: string; redirectUri: string }>(
      (resolve, reject) => {
        messageHandler = (ev: MessageEvent) => {
          if (ev.origin != location.origin) {
            console.log("Unauthorized message", ev);
            return;
          }
          if (!ev.data.code) {
            return;
          }

          resolve({
            code: ev.data.code,
            redirectUri: redirectUri.href
          });
        };
        window.addEventListener("message", messageHandler);
        closeWatch = setInterval(() => {
          if (popup.closed) reject(new Error("closed"));
        }, 500);
      }
    );
  } finally {
    window.removeEventListener("message", messageHandler!);
    popup.close();
    clearInterval(closeWatch);
  }
}

export async function signInSSO(service: SsoService) {
  const result = await ssoSignin(service);

  const token = await handle(
    authSignin(service, {
      ...result
    }),
    [200, 400]
  );
  if (token.status != 200) {
    return token;
  }

  const globalStore = useGlobalStore();
  globalStore.setToken(token.data);
  return token;
}
