<template>
  <div
    class="w-full md:w-2/5 container-widget bg-white p-7 flex flex-col gap-y-5 text-center">
    <div>
      <div class="text-lg font-bold">
        {{
          i18n.t(
            "Due to russian invasion of Ukraine, the flights from/to the country are suspended"
          )
        }}
      </div>
      <div class="text-lg">
        {{ i18n.t("Please choose any neighbouring country to find a flight") }}
      </div>
    </div>
    <PopularDestinations
      :destinations="transits"
      @submit="
        event => {
          props.side == 'from'
            ? (pageStore.search.origin = event)
            : (pageStore.search.destination = event);
          pageEmitter.emit('highlightLocations', props.side);
          emit('close');
        }
      " />
    <button class="w-full bg-gray-300" @click="emit('close')">
      {{ i18n.t("Close") }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { Location, routerGetLocation } from "../api/client";
import PopularDestinations from "./PopularDestinations.vue";
import { useMainPageEmitter, useMainPageStore } from "./store";
import { useI18n } from "../locale";
import { computedAsync } from "@vueuse/core";
import { ok } from "oazapfts";

const props = defineProps<{
  side: "from" | "to";
}>();
const emit = defineEmits<{
  (e: "close"): void;
}>();

const pageStore = useMainPageStore();
const pageEmitter = useMainPageEmitter();
const i18n = useI18n();

const transits = computedAsync<Location[]>(
  () =>
    Promise.all([
      ok(routerGetLocation("chisinau_md")),
      ok(routerGetLocation("warsaw_pl"))
    ]),
  []
);
</script>

<style scoped></style>
