<template>
  <div class="container-widget bg-white p-10 flex flex-col gap-5">
    <div class="flex gap-3 items-center">
      <div
        class="outline outline-2 outline-gray-300 rounded-md p-2 py-1 bg-gray-100 cursor-pointer"
        @click="copyLink">
        {{ origin }}/story/{{ id }}
      </div>
      <button
        class="outline outline-2 outline-blue-400 rounded-md h-full"
        :class="{
          'outline-green-400 bg-green-300 font-bold': linkCopied,
          'bg-blue-300': !linkCopied
        }"
        @click="copyLink">
        <div v-if="!linkCopied">Copy link</div>
        <div v-else class="flex gap-3 items-center">
          <icon icon="check" /> Copied!
        </div>
      </button>
    </div>
    <button class="bg-gray-300" @click="$emit('close')">Close</button>
  </div>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { ref } from "vue";

const props = defineProps<{
  id: string;
}>();
const origin = document.location.origin;

const clipboard = useClipboard();
const linkCopied = ref(false);

function copyLink() {
  clipboard.copy(`${origin}/story/${props.id}`);
  linkCopied.value = true;
}
</script>

<style scoped></style>
