<template>
  <div
    class="flex flex-wrap md:flex-row md:flex-nowrap text-center justify-center gap-y-6 md:w-[50rem]">
    <div class="star">
      <img class="icon" src="./airport-icon.svg" alt="" />
      <div class="header">{{ i18n.t("See all info in one place") }}</div>
      <div class="">
        {{ i18n.t("Instead of visiting tens of sites and forums") }}
      </div>
    </div>
    <div class="star">
      <img class="icon" src="./immigration-officer-icon.svg" alt="" />
      <div class="header">{{ i18n.t("Spend 15 minutes now") }}</div>
      <div class="">
        {{
          i18n.t(
            "Don't waste time and money on flights that will be unavailable for you"
          )
        }}
      </div>
    </div>
    <div class="star" @click="showDocuments">
      <img class="icon" src="./passport-ticket-icon.svg" alt="" />
      <div class="header">
        {{ i18n.t("Unique solutions for all passports") }}
      </div>
      <div class="">
        {{ i18n.t("As well as visas, permits and other documents") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick } from "vue";
import { useMainPageEmitter, useMainPageStore } from "../store";
import { useI18n } from "../../locale";

const mainPageStore = useMainPageStore();
const mainPageEmitter = useMainPageEmitter();
const i18n = useI18n();

async function showDocuments() {
  mainPageStore.showDocuments = true;
  await nextTick();
  mainPageEmitter.emit("scrollToDocuments");
}
</script>

<style lang="sass" scoped>
.header
  @apply font-bold mt-3 min-w-fit

.icon
  @apply h-32

.star
  @apply flex flex-col items-center w-[20rem] md:basis-1/3
</style>
