<template>
  <div>
    <div
      class="flex flex-col items-start md:items-center md:flex-row gap-x-2 gap-y-2">
      <div class="mr-1">{{ i18n.t("Sort by:") }}</div>
      <div class="flex flex-row items-center w-full md:w-auto">
        <button
          v-for="(value, key, idx) in sortOrders"
          class="bg-blue-100 rounded-none min-h-full self-stretch flex flex-col md:flex-row gap-x-2 justify-center items-center text-sm md:text-base trucate flex-auto"
          :class="{
            'bg-blue-400': sortOrder == key,
            'rounded-l-md': idx == 0,
            'rounded-r-md': idx == Object.keys(sortOrders).length - 1
          }"
          :disabled="disable"
          @click="sortOrder = key">
          <icon :icon="value!.icon" />
          {{ value!.text }}
        </button>
      </div>
      <div class="mr-1">{{ i18n.t("Hide:") }}</div>
      <div class="flex flex-row items-center w-full md:w-auto">
        <button
          :class="{
            'bg-blue-400': filters.hideFatals
          }"
          :disabled="disable"
          class="bg-blue-100 rounded-md flex items-center justify-center gap-x-2 self-stretch text-sm md:text-base min-h-[2.7rem] md:min-h-0 flex-auto"
          @click="filters.hideFatals = !filters.hideFatals">
          <icon class="text-red-500" icon="fa-circle-xmark" />
          {{ i18n.t("Visa required") }}
        </button>
      </div>
    </div>
    <div class="flex mt-2 items-center">
      <div class="mr-1">{{ i18n.t("Max Stops:") }}</div>
      <div class="flex flex-row items-center w-full md:w-auto">
        <button
          v-for="opt in stopsFilter"
          @click="filters.stops = opt"
          class="bg-blue-100 rounded-none first:rounded-l-md last:rounded-r-md self-stretch flex-auto"
          :class="{
            'bg-blue-400': filters.stops == opt
          }"
          :disabled="disable">
          {{ opt ?? i18n.t("Any") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SortOrder } from "./sort_filter";
import { useMainPageStore } from "./store";
import { toRefs } from "vue";
import { useI18n } from "../locale";

defineProps<{
  disable?: boolean;
}>();
const i18n = useI18n();

const stopsFilter = [undefined, 1, 2, 3, 4];
const sortOrders: Partial<Record<SortOrder, { text: string; icon: string }>> = {
  visa: { text: i18n.t("Visa"), icon: "fa-passport" },
  cheapest: { text: i18n.t("Cheapest"), icon: "fa-dollar-sign" },
  fastest: { text: i18n.t("Fastest"), icon: "fa-clock" },
  cheapest_fastest: {
    text: i18n.t("Cheapest and Fastest"),
    icon: "fa-certificate"
  }
};

const { filters, sortOrder } = toRefs(useMainPageStore());
</script>

<style lang="sass" scoped>
button[disabled]
  @apply bg-gray-300
</style>
