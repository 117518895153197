import { defineStore } from "pinia";
import {
  DocumentDto,
  TokenResponse,
  User,
  usersAddDocuments,
  usersGetMe,
  usersListDocuments,
  usersUpdateMe
} from "./api/client";
import { computed, ref, watch, watchEffect } from "vue";
import { handle200, Token } from "./api";
import { postEvent } from "./analytics";
import { sift } from "radash";
import { klona } from "klona";
import {
  documentIssuers,
  documentTypes,
  passportType
} from "./main_page/documents/common";
import { whenever } from "@vueuse/core";
import cuid2 from "@paralleldrive/cuid2";
import { currencies, defaultCurrency } from "./components/currency/currency";
import { guessDefaultLocale, SUPPORTED_LOCALES } from "./locale";

const authChannel = new BroadcastChannel("auth");
authChannel.addEventListener("message", msg => {
  const store = useGlobalStore();
  store.token = msg.data;
});

export const useGlobalStore = defineStore(
  "main",
  () => {
    const token = ref<Token>();
    const user = ref<User>();

    function setToken(resp: TokenResponse | null) {
      if (resp)
        token.value = {
          access: resp.access_token,
          refresh: resp.refresh_token,
          expiresAt: new Date().getTime() + resp.expires_in * 1000
        };
      else token.value = undefined;
      authChannel.postMessage(klona(token.value));
    }

    watch(token, async () => {
      if (!token.value) {
        user.value = undefined;
        return;
      }

      const me = await usersGetMe();
      if (me.status == 200) {
        user.value = me.data;
        gtag("set", "user_properties", {
          user_email: me.data.email
        });
      } else user.value = undefined;

      if (me.status == 403) setToken(null);
    });

    const documents = ref<((DocumentDto & { id: string }) | null)[]>([]);
    watch(user, async user => {
      if (!user) return;
      if (documents.value.length > 0) {
        usersAddDocuments({
          documents: sift(documents.value).map(d => ({
            type: d.type.id,
            issuer: d.issuer.id
          }))
        });
      } else {
        const res = await handle200(usersListDocuments());
        if (res.data.length > 0)
          documents.value = res.data.map(d => ({ ...d, id: cuid2.createId() }));
      }
    });
    watch(documents, docs => {
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        if (doc && (!doc.type || !doc.issuer)) {
          docs.splice(i, 1);
          i--;
        }
      }

      postEvent("documents", {
        documents: sift(docs)
          .filter(d => d.type && d.issuer)
          .map(d => ({
            type: d.type.canonicalName,
            issuer: d.issuer.canonicalName
          }))
      });
    });

    whenever(
      () => documentTypes.value.length && documentIssuers.value.length,
      () => {
        watch(documents, docs => {
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i];
            if (
              doc != null &&
              (!doc.type ||
                !doc.type.id ||
                !documentTypes.value.find(t => t.id == doc.type.id) ||
                !doc.issuer ||
                !doc.issuer.id ||
                !documentIssuers.value.find(i => i.id == doc.issuer.id))
            ) {
              docs.splice(i, 1);
              i--;
            }
          }
        });
      }
    );

    watchEffect(() => {
      const docs = documents.value;
      if (docs.length == 0) docs.push(null);
      const passportTypeId = passportType.value?.id;
      if (!passportTypeId) return;

      const first = docs[0];
      if (first && first.type.id != passportTypeId) {
        const firstPass = docs.findIndex(d => d && d.type.id == passportTypeId);
        if (firstPass != -1) {
          const temp = docs[0];
          docs[0] = docs[firstPass];
          docs[firstPass] = temp;
        } else {
          docs.splice(0, 0, null);
        }
      }
    });

    const selectedCurrency = ref<string>();
    const currency = computed(
      () => selectedCurrency.value ?? defaultCurrency.value
    );

    whenever(user, user => {
      if (user.currency) {
        selectedCurrency.value = user.currency;
      }
    });
    watch(selectedCurrency, currency => {
      if (currency) {
        if (!currencies.find(c => c.currencyCode == currency))
          selectedCurrency.value = undefined;
        else if (user.value && user.value?.currency != currency)
          usersUpdateMe({
            currency: currency
          });
      }
    });

    const selectedLocale = ref<string>();
    const locale = computed(() => selectedLocale.value ?? guessDefaultLocale());
    watch(selectedLocale, lang => {
      if (lang && !SUPPORTED_LOCALES.includes(lang))
        selectedLocale.value = undefined;
    });

    const storiesClaimCodes = ref<string[]>([]);

    return {
      token,
      user,
      setToken,

      documents,

      selectedCurrency,
      currency,

      locale,
      selectedLocale,

      storiesClaimCodes
    };
  },
  {
    persist: {
      paths: [
        "token",
        "documents",
        "selectedCurrency",
        "selectedLocale",
        "storiesClaimCodes"
      ]
    }
  }
);
