<template>
  <div class="w-full md:w-[50rem] self-center flex flex-col items-center">
    <div class="flex w-full flex-col items-center mb-6">
      <div class="text-4xl lg:text-6xl">Passportal</div>
      <div class="text-xl text-gray-700 mt-2">
        {{ i18n.t("We match tickets specifically to your passport") }}
      </div>
    </div>
    <div
      class="self-stretch flex flex-col container-widget self-shrink bg-white py-7">
      <Documents>
        <div class="text-2xl text-center">
          {{
            i18n.t(
              "Select your citizenship and let us find personalized tickets for you"
            )
          }}
        </div>
      </Documents>
      <button class="mx-5" @click="next">{{ i18n.t("Next") }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "../store";
import Documents from "../main_page/documents/Documents.vue";
import { sift } from "radash";
import { useRouter } from "vue-router";
import { useI18n } from "../locale";

const globalStore = useGlobalStore();
const router = useRouter();
const i18n = useI18n();

function next() {
  if (sift(globalStore.documents).length != 0) router.replace("/index");
}
</script>

<style scoped></style>
