<template>
  <div class="flex flex-col gap-2 w-7 items-center self-stretch">
    <icon
      icon="caret-up"
      class="cursor-pointer h-5 order-first"
      :class="{ 'text-red-500': voted }"
      @click="doVote" />
    <div class="flex items-center">{{ votesCount }}</div>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from "@urql/vue";
import { Story } from "../../api/gql/graphql";
import { graphql } from "../../api/gql";
import { computed, ref, watch } from "vue";
import { useGlobalStore } from "../../store";
import { useRouter } from "vue-router";

const props = defineProps<{
  story: Story;
}>();

const globalStore = useGlobalStore();
const votesCount = computed(() => {
  if (voted.value == props.story.myVote) {
    return props.story.votesCount;
  }

  if (voted.value) {
    return props.story.votesCount + 1;
  } else {
    return props.story.votesCount - 1;
  }
});

const router = useRouter();
const voted = ref(props.story.myVote);

const vote = useMutation(
  graphql(`
    mutation Vote($storyId: String!, $vote: Boolean!) {
      voteForStory(storyId: $storyId, vote: $vote)
    }
  `)
);

watch(vote.fetching, fetching => {
  if (!fetching) {
    if (vote.data.value?.voteForStory) {
      voted.value = !voted.value;
    }
  }
});

function doVote() {
  if (globalStore.user) {
    vote.executeMutation({ storyId: props.story.id, vote: !voted.value });
  } else {
    router.push({ path: "/stories/signin/vote" });
  }
}
</script>

<style scoped></style>
