<template>
  <div
    class="flex flex-col w-72 container-widget p-7 w-max max-w-screen max-h-screen overflow-y-auto bg-white">
    <div v-if="showConfirmation" class="text-lg font-semibold">
      {{ i18n.t("Check your email for confirmation") }}
    </div>
    <form v-else class="flex flex-col gap-y-4" @submit.prevent>
      <slot>
        <div class="text-xl font-semibold text-center">
          {{ i18n.t("Register for better experience") }}
        </div>
        <div class="text-lg leading-6 text-center">
          <div>* {{ i18n.t("Save documents for later") }}</div>
          <div>* {{ i18n.t("Get the best support when needed") }}</div>
          <div>* {{ i18n.t("Receive unique deals and news") }}</div>
        </div>
      </slot>
      <template v-if="showButtons">
        <a class="button continue-with" @click="showButtons = false">
          <icon icon="fa-envelope" class="w-[20.8px]" />
          <div>{{ i18n.t("Continue with Email") }}</div>
        </a>
        <a class="button continue-with" @click="doSignInSSO('google')">
          <img src="./google.svg" />
          <div>{{ i18n.t("Or continue with Google") }}</div>
        </a>
      </template>
      <div
        v-else
        class="flex flex-col gap-y-4 items-stretch"
        @submit.prevent
        data-openreplay-hidden>
        <input
          v-model="email"
          placeholder="Email"
          required
          type="email"
          @blur="checkEmail"
          @focus="
            () => {
              showPassword = true;
              postEvent('signin.focus.email');
            }
          " />
        <ErroneousInput
          v-if="showPassword"
          minlength="8"
          placeholder="Password"
          type="password"
          required
          v-model="password"
          :error-message="wrongPassword ? i18n.t('Wrong password') : ''"
          @focus="postEvent('signin.focus.password')" />
        <template v-if="showRegistration">
          <div class="flex flex-col">
            {{ i18n.t("Age") }}
            <select v-model="age" class="mt-1" required>
              <option value="17">&lt;17</option>
              <option value="18">18-25</option>
              <option value="26">26-35</option>
              <option value="36">36-45</option>
              <option value="46">46-55</option>
              <option value="56">56-65</option>
              <option value="66">66+</option>
            </select>
          </div>
          <div class="flex items-center gap-x-5 [&>*>input]:mr-2">
            <div>
              <input
                v-model="gender"
                name="age"
                type="radio"
                value="MALE"
                id="male"
                required /><label for="male">{{ i18n.t("Male") }}</label>
            </div>
            <div>
              <input
                v-model="gender"
                name="age"
                type="radio"
                id="female"
                value="FEMALE" /><label for="female">{{
                i18n.t("Female")
              }}</label>
            </div>
            <div>
              <input
                v-model="gender"
                name="age"
                type="radio"
                id="other"
                value="OTHER" /><label for="other">{{ i18n.t("Other") }}</label>
            </div>
          </div>
        </template>
      </div>
      <div>
        <div>
          <input v-model="agreeToTOS" type="checkbox" disabled required /><span
            class="ml-3">
            {{ i18n.t("I agree with") }}
            <a :href="config.links.tos" target="_blank">{{
              i18n.t("terms of use")
            }}</a>
            {{ i18n.t("and") }}
            <a :href="config.links.pp" target="_blank">{{
              i18n.t("privacy policy")
            }}</a>
          </span>
        </div>
      </div>
      <div>
        <div>
          <input v-model="subscribe" type="checkbox" id="sub" /><label
            for="sub"
            class="ml-3">
            {{ i18n.t("Subscribe to email newsletter") }}
          </label>
        </div>
      </div>
      <button
        v-if="showPassword"
        class="self-center !py-2 w-full"
        @click="
          () => {
            postEvent('signin.click.signin');
            doSignIn();
          }
        ">
        {{ showRegistration ? i18n.t("Sign up") : i18n.t("Sign in") }}
      </button>
      <button class="continue-with" @click.prevent="router.back()">
        <icon icon="fa-circle-xmark" />
        <div>
          {{
            suggestive
              ? i18n.t("Continue without registration")
              : i18n.t("Close")
          }}
        </div>
      </button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref, watch } from "vue";
import { authSigninManual, emailSubscriptionsSubscribe } from "../api/client";
import { handle, httpError } from "../api";
import { signInSSO, SsoService } from "./sso";
import ErroneousInput from "../components/ErroneousInput.vue";
import { postEvent } from "../analytics";
import { useGlobalStore } from "../store";
import { useRouter } from "vue-router";
import { whenever } from "@vueuse/core";
import { useMainPageStore } from "../main_page/store";
import { useI18n } from "../locale";
import { useMutation } from "@urql/vue";
import { graphql } from "../api/gql";

const props = defineProps<{
  suggestive?: boolean;
}>();

const router = useRouter();
const globalStore = useGlobalStore();
const mainPageStore = useMainPageStore();
const i18n = useI18n();

const showButtons = ref(true);
const showPassword = ref(false);
const showRegistration = ref(false);
const showConfirmation = ref(false);

const analyticsClickedSSO = ref(false);

const email = ref("");
const password = ref("");
const age = ref<number>();
const gender = ref("");
const agreeToTOS = ref(true);
const subscribe = ref(false);

const wrongPassword = ref(false);

const config = window.config;

const analyticsData = {
  suggestive: props.suggestive,
  reqId: mainPageStore.lastSearch?.reqId
};

const claimStories = useMutation(
  graphql(`
    mutation ClaimStories($claimCodes: [String!]!) {
      claimStories(claimCodes: $claimCodes)
    }
  `)
);

postEvent("signin.open", analyticsData);

watch(email, () => {
  showRegistration.value = false;
});
watch(age, () => {
  postEvent("signin.entered.age");
});
watch(gender, () => {
  postEvent("signin.entered.gender");
});

whenever(
  () => globalStore.user,
  () => {
    if (globalStore.storiesClaimCodes.length) {
      claimStories
        .executeMutation({ claimCodes: globalStore.storiesClaimCodes })
        .then(r => {
          if (!r.error) {
            globalStore.storiesClaimCodes = [];
          }
        });
    }
    router.back();
  }
);

onUnmounted(() => {
  const interacted = !showButtons.value || analyticsClickedSSO.value;
  const data = {
    ...analyticsData,
    interacted,
    registered: interacted && globalStore.user
  };
  postEvent("signin.close", data);
});

async function checkEmail() {
  const resp = await authSigninManual({
    email: email.value,
    password: "not-a-password"
  });
  if (
    resp.status == 400 &&
    resp.data.errors[0]?.code == "registration_required"
  ) {
    showRegistration.value = true;
  }
}

async function doSignIn() {
  if (showRegistration.value) {
    postEvent("signin.registration", analyticsData);

    if (subscribe.value) emailSubscriptionsSubscribe({ email: email.value });
    const resp = await handle(
      authSigninManual({
        email: email.value,
        password: password.value,
        registration: {
          dob: new Date(
            Date.UTC(new Date().getFullYear() - age.value!, 0, 1, 1, 1, 1)
          ).toISOString(),
          gender: gender.value as any
        }
      }),
      [400]
    );
    if (resp.data.errors[0]?.code == "confirmation_required")
      showConfirmation.value = true;
    else throw httpError(resp);
  } else {
    postEvent("signin.auth_password");

    const resp = await authSigninManual({
      email: email.value,
      password: password.value
    });
    if (resp.status == 200) {
      globalStore.setToken(resp.data);
    } else if (resp.status == 400) {
      switch (resp.data.errors[0]?.code) {
        case "wrong_password":
          wrongPassword.value = true;
          break;
        case "registration_required":
          showRegistration.value = true;
          break;
        default:
          throw httpError(resp);
      }
    } else throw httpError(resp);
  }
}

async function doSignInSSO(service: SsoService) {
  analyticsClickedSSO.value = true;
  await signInSSO(service);
  if (globalStore.user?.email && subscribe.value)
    emailSubscriptionsSubscribe({ email: globalStore.user.email });
}
</script>

<style lang="sass" scoped>
.continue-with
  @apply py-2 bg-gray-300 flex grid items-center text-center bg-white border-2 border-gray-300 cursor-pointer
  > *
    @apply row-start-1 col-start-1

input, label
  @apply cursor-pointer
</style>
