<template>
  <div class="flex flex-col items-center">
    <div
      class="flex flex-row p-3 gap-3 w-full md:max-w-[70%] lg:max-w-[50%]"
      v-if="story">
      <Votes :story="story" />

      <div class="flex flex-col grow gap-3">
        <div>
          <h1 class="w-0 min-w-full text-lg font-bold">
            {{ story.title }}
          </h1>
          <div class="flex flex-row w-full">
            <div class="w-0 grow">
              <p v-for="p of story.body.split('\n')" v-text="p"></p>
            </div>
          </div>
        </div>

        <StoryHeader :story="story" />
        <div class="text-sm flex items-center">
          <span class="font-bold">
            {{
              story.author?.firstName
                ? story.author.firstName + " " + (story.author.lastName ?? "")
                : i18n.t("Anonymous")
            }}
          </span>
          <span class="ml-3">
            {{
              story.createdAt.toLocaleDateString("en-us", {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </span>
          <div
            class="flex flex-row items-center gap-1 hover:text-blue-500 cursor-pointer"
            @click.stop="shareModal = true">
            <icon class="ml-3 p-2" icon="share" />
            {{ i18n.t("Share") }}
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
    <Overlay v-model="shareModal">
      <StoryShareModal :id="story!.id" @close="shareModal = false" />
    </Overlay>
  </div>
</template>

<style lang="sass" scoped>
.flag-shadow
  @apply drop-shadow rounded-md h-7 w-7
</style>

<script setup lang="ts">
import { computed, reactive, ref, toRef } from "vue";
import { useQuery } from "@urql/vue";
import { graphql } from "../../api/gql";
import Loader from "../../components/Loader.vue";
import StoryShareModal from "./StoryShareModal.vue";
import Overlay from "../../components/overlay/Overlay.vue";
import StoryHeader from "./StoryHeader.vue";
import Votes from "./Votes.vue";
import { useI18n } from "../../locale";
import { postEvent } from "../../analytics";

const props = defineProps<{
  id: string;
}>();

const shareModal = ref(false);
const i18n = useI18n();

postEvent("stories.details.opened");

const getStory = useQuery({
  query: graphql(`
    query GetStory($id: ID!) {
      getStory(id: $id) {
        id
        title
        body
        createdAt
        slug
        author {
          id
          firstName
          lastName
        }
        documents {
          type {
            id
            name
            canonicalName
          }
          issuer {
            id
            name
            canonicalName
            countryCode
          }
        }
        stops {
          country {
            id
            name
            code
          }
        }
        votesCount
        myVote
        date {
          year
          month
          day
        }
      }
    }
  `),
  variables: reactive(props),
  context: {
    locale: toRef(() => i18n.locale)
  }
});
const story = computed(() =>
  getStory.fetching.value ? undefined : getStory.data.value?.getStory
);
</script>
