import {
  computedAsync,
  tryOnScopeDispose,
  useAnimate,
  UseAnimateReturn
} from "@vueuse/core";
import mitt, { Emitter, EventType } from "mitt";
import { Ref } from "vue";

export class Mutex {
  private lock: Promise<void> | null = null;
  private unlock: (() => void) | null = null;

  async acquire() {
    if (this.lock) await this.lock;
    this.lock = new Promise<void>(resolve => {
      this.unlock = resolve;
    });
  }

  release() {
    this.lock = null;
    this.unlock!();
  }

  async run<T>(cb: () => Promise<T>): Promise<T> {
    try {
      await this.acquire();
      return await cb();
    } finally {
      this.release();
    }
  }
}

interface GeoIP {
  countryCode: string;
  country: string;
  city?: string;
}

export const useGeoIP = computedAsync<GeoIP | undefined>(async () => {
  const resp = await fetch("https://get.geojs.io/v1/ip/geo.json").then(resp =>
    resp.json()
  );
  return {
    countryCode: resp.country_code,
    city: resp.city,
    country: resp.country
  };
});

export function defineEmitter<
  Events extends Record<EventType, unknown>
>(): () => Emitter<Events> {
  const emitter = mitt<Events>();
  return () => emitter;
}

export function useEmitterEvent<
  Events extends Record<EventType, unknown>,
  E extends keyof Events,
  CB extends (event: Events[E]) => void
>(emitter: Emitter<Events>, event: E, cb: CB) {
  emitter.on(event, cb);
  tryOnScopeDispose(() => emitter.off(event, cb));
}

export function findScrollableParent(el: HTMLElement) {
  if (el.scrollHeight > el.clientHeight) {
    return el;
  } else {
    const parent = el.parentElement;
    if (!parent) return null;
    return findScrollableParent(parent);
  }
}

export function useAnimateGreenFlash(el: Ref<any>) {
  let anim: UseAnimateReturn | undefined = undefined;
  return () => {
    if (!anim)
      anim = useAnimate(
        el,
        [
          { outline: "", outlineColor: "lightgreen" },
          {
            outlineStyle: "solid",
            outlineWidth: "1rem",
            outlineColor: "lightgreen"
          },
          { outline: "", outlineColor: "lightgreen" }
        ],
        {
          immediate: false,
          duration: 700
        }
      );

    anim.play();
  };
}

function _min(d0: number, d1: number, d2: number, bx: number, ay: number) {
  return d0 < d1 || d2 < d1
    ? d0 > d2
      ? d2 + 1
      : d0 + 1
    : bx === ay
    ? d1
    : d1 + 1;
}

export function levenshteinDistance(a: string, b: string) {
  if (a === b) {
    return 0;
  }

  if (a.length > b.length) {
    var tmp = a;
    a = b;
    b = tmp;
  }

  var la = a.length;
  var lb = b.length;

  while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
    la--;
    lb--;
  }

  var offset = 0;

  while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
    offset++;
  }

  la -= offset;
  lb -= offset;

  if (la === 0 || lb < 3) {
    return lb;
  }

  var x = 0;
  var y;
  var d0;
  var d1;
  var d2;
  var d3;
  var dd;
  var dy;
  var ay;
  var bx0;
  var bx1;
  var bx2;
  var bx3;

  var vector = [];

  for (y = 0; y < la; y++) {
    vector.push(y + 1);
    vector.push(a.charCodeAt(offset + y));
  }

  var len = vector.length - 1;

  for (; x < lb - 3; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    bx1 = b.charCodeAt(offset + (d1 = x + 1));
    bx2 = b.charCodeAt(offset + (d2 = x + 2));
    bx3 = b.charCodeAt(offset + (d3 = x + 3));
    dd = x += 4;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      ay = vector[y + 1];
      d0 = _min(dy, d0, d1, bx0, ay);
      d1 = _min(d0, d1, d2, bx1, ay);
      d2 = _min(d1, d2, d3, bx2, ay);
      dd = _min(d2, d3, dd, bx3, ay);
      vector[y] = dd;
      d3 = d2;
      d2 = d1;
      d1 = d0;
      d0 = dy;
    }
  }

  for (; x < lb; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    dd = ++x;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
      d0 = dy;
    }
  }

  return dd ?? 99999999999;
}

export const colorBySeverity = {
  text: {
    info: "text-green-600",
    warning: "text-amber-600",
    fatal: "text-red-600"
  },
  bg: {
    info: "",
    warning: "bg-amber-600",
    fatal: "bg-red-600"
  },
  bgAll: {
    info: "bg-green-600",
    warning: "bg-amber-600",
    fatal: "bg-red-600"
  },
  bgLight: {
    info: "",
    warning: "bg-orange-100",
    fatal: "bg-rose-100"
  }
};
