<template>
  <Overlay :model-value="true" route>
    <Signin>
      <div v-if="kind == 'vote'" class="text-center font-bold text-lg">
        Please signin to like.
      </div>
    </Signin>
  </Overlay>
</template>

<script setup lang="ts">
import { useGlobalStore } from "../store";
import Overlay from "../components/overlay/Overlay.vue";
import Signin from "../signin/Signin.vue";
import { whenever } from "@vueuse/core";
import { useRouter } from "vue-router";

defineProps<{
  kind: string;
}>();

const globalStore = useGlobalStore();
const router = useRouter();

whenever(
  () => globalStore.user,
  () => {
    router.back();
  }
);
</script>

<style scoped></style>
