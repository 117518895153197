<template>
  <div
    v-if="show"
    class="w-screen h-[100dvh] left-0 top-0 fixed bg-[rgba(0,0,0,.1)] flex justify-center items-center z-[5]"
    @click.self="close">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { syncRef, useScrollLock, useVModel } from "@vueuse/core";
import { watch } from "vue";
import { overlays } from "./overlay";
import { useRouter } from "vue-router";

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    route?: boolean;
  }>(),
  {
    modelValue: false
  }
);
const emit = defineEmits<{
  (e: "update:modelValue", show: boolean): void;
}>();

const router = useRouter();
const show = useVModel(props, "modelValue", emit);

watch(show, v => {
  if (v) {
    overlays.push(() => close());
  } else {
    overlays.pop();
  }
});

const scrollLock = useScrollLock(document.body);
syncRef(show, scrollLock, {
  direction: "ltr"
});

function close() {
  if (props.route) {
    router.back();
  } else {
    show.value = false;
  }
}
</script>

<style scoped></style>
