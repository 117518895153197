<template>
  <div v-text="text"></div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const text = ref("");

function handle() {
  const opener = window.opener as Window;
  if (opener.origin != location.origin) {
    return "Unauthorized opener";
  }

  console.log(location.href);
  opener.postMessage({ code: new URL(location.href).searchParams.get("code") });
  return "Success";
}

text.value = handle();
</script>

<style scoped></style>
