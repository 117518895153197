<template>
  <div class="flex flex-col container-widget item p-4">
    <div class="text-xl mb-4">{{ i18n.t("Routes and Tickets") }}</div>
    <Sort class="mb-4" />
    <div v-if="showLoader" class="flex h-fit w-fit p-3 items-center gap-5">
      <div class="w-12">
        <div v-if="pageStore.searchInProgress" v-html="loaderSvg"></div>
        <img v-else src="/favicon.svg" />
      </div>
      <div class="text-2xl font-bold" v-text="statusText"></div>
    </div>
    <TicketsNotFound
      v-if="!search.routes.length && !pageStore.searchInProgress"
      class="tickets-error text-lg" />
    <div
      v-else-if="!sfRoutes.length && !pageStore.searchInProgress"
      class="tickets-error text-xl">
      {{
        i18n.t(
          "Sorry there are no tickets for your filter settings. Please try to change filters"
        )
      }}
    </div>
    <div
      v-else-if="otherDates && !pageStore.searchInProgress"
      class="self-center text-2xl text-center">
      {{ i18n.t("There are no tickets found for your date") }} ({{
        intlFormat(selectedDate)
      }})<br />
      {{ i18n.t("Flights are available on other dates:") }}
    </div>
    <div v-for="{ date, routes } of routesByDate" class="flex flex-col">
      <div
        v-if="otherDates"
        class="self-center py-2 text-xl"
        v-text="intlFormat(date)"></div>
      <Routes :routes="routes" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Routes from "./Routes.vue";
import { filterRoutes, sortRoutes } from "../sort_filter";
import { useMainPageStore } from "../store";
import { computed, toRef } from "vue";
import { compareAsc, intlFormat, isEqual, startOfDay } from "date-fns";
import TicketsNotFound from "./TicketsNotFound.vue";
import { group } from "radash";
import Sort from "../Sort.vue";
import loaderSvg from "../../components/logo-animated.svg?raw";
import { useI18n } from "../../locale";

defineProps<{
  showLoader?: boolean;
}>();

const pageStore = useMainPageStore();
const i18n = useI18n();

const search = toRef(() => pageStore.lastSearch!);
const selectedDate = toRef(() => startOfDay(search.value.date));

const statusText = computed(() => {
  if (pageStore.searchInProgress) return i18n.t("More tickets are loading...");
  if (search.value.error)
    return i18n.t("Search ended with errors, not all tickets are loaded");
  if (search.value.routes.length) return i18n.t("All tickets are loaded");
  return i18n.t("Sorry, no tickets were found. Please try another search.");
});
const sfRoutes = computed(() => {
  const search = pageStore.lastSearch;
  if (!search) return [];

  return filterRoutes(
    sortRoutes(search.routes, pageStore.sortOrder),
    pageStore.filters
  );
});
const routesByDate = computed(() => {
  return Object.entries(group(sfRoutes.value, r => r.date))
    .map(([k, v]) => ({ date: startOfDay(new Date(k)), routes: v! }))
    .sort((a, b) => {
      if (isEqual(a.date, b.date)) return 0;
      if (isEqual(a.date, selectedDate.value)) return -1;
      if (isEqual(b.date, selectedDate.value)) return 1;
      return compareAsc(a.date, b.date);
    });
});
const otherDates = computed(() =>
  routesByDate.value.find(r => !isEqual(r.date, selectedDate.value))
);
</script>

<style scoped lang="sass">
.tickets-error
  @apply text-gray-600 flex flex-col text-center px-1 py-20 rounded
  box-shadow: inset 0 10px 10px 0 rgb(0 0 0 / 0.05)
</style>
