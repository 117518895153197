/**
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "/"
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type User = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  currency: string | null;
};
export type UserUpdate = {
  currency?: string;
};
export type DocumentType = {
  id: string;
  name: string;
  canonicalName: string;
};
export type DocumentIssuer = {
  id: string;
  name: string;
  canonicalName: string;
  countryCode: string | null;
};
export type DocumentDto = {
  type: DocumentType;
  issuer: DocumentIssuer;
};
export type DocumentInput = {
  type: string;
  issuer: string;
};
export type DocumentsInput = {
  documents: DocumentInput[];
};
export type RegistrationData = {
  dob: string;
  gender: "MALE" | "FEMALE" | "OTHER";
};
export type ManualSignInRequest = {
  email: string;
  password: string;
  registration?: RegistrationData;
};
export type TokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
};
export type ApiError = {
  code: string;
  message: string;
  path?: string;
};
export type ApiErrors = {
  errors: ApiError[];
};
export type ManualSignInConfirmRequest = {
  code: string;
};
export type SignInRequest = {
  code: string;
  redirectUri: string;
};
export type RefreshTokenRequest = {
  refreshToken: string;
};
export type FindRoutesRequest = {
  origin: string;
  destination: string;
  date: string;
  documents: DocumentInput[];
  suggestedBy?: string[];
  isFirst: boolean;
};
export type FindRoutesResponse = {
  id: string;
};
export type RateLimitResponse = {
  waitUntil?: string;
};
export type Coordinates = {
  lat: number;
  lng: number;
};
export type Location = {
  id: string;
  type: "airport" | "city";
  name: string;
  code: string;
  cityName: string;
  cityCode: string;
  countryName: string;
  countryCode: string;
  coordinates: Coordinates;
};
export type CheckResultMessage = {
  text: string;
  severity: "info" | "warning" | "fatal";
};
export type CheckResult = {
  severity: "info" | "warning" | "fatal";
  messages: CheckResultMessage[];
};
export type Carrier = {
  code: string;
  name: string;
};
export type Segment = {
  from: Location;
  to: Location;
  duration: number;
  departure: string;
  arrival: string;
  check?: CheckResult;
  carrier?: Carrier;
};
export type SegmentGroup = {
  kind: "plane" | "manual";
  segments: Segment[];
  price: number;
  url: string;
};
export type RelatedStory = {
  id: string;
  title: string;
};
export type Route = {
  segments: SegmentGroup[];
  duration: number;
  price: number;
  date: string;
  stories?: RelatedStory[];
};
export type SearchResult = {
  routes: Route[];
  isComplete: boolean;
};
export type CompleteLocationResponse = {
  locations: Location[];
};
export type ReportIncorrectInfoRequest = {
  request: FindRoutesRequest;
  response: SearchResult;
  incorrectRoute: Route;
};
export type EmailSubscription = {
  email: string;
};
export type PostEventRequest = {
  name: string;
  data?: object;
};
export type CheckVisaRequest = {
  origin: string;
  destination: string;
  documents: DocumentInput[];
  departureDate: string;
};
export type CheckVisaResponse = {
  passports: DocumentInput[];
  isGood: boolean[];
};
export type VisaCheckWorldRequest = {
  documents: DocumentInput[];
  originCountry: string;
};
export type CountryCheckResult = {
  countryCode: string;
  check: CheckResult;
};
export type VisaCheckWorldResponse = {
  results: CountryCheckResult[];
};
export function appHealthz(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchText("/healthz", {
    ...opts
  });
}
export function usersGetMe(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: User;
      }
    | {
        status: 403;
      }
  >("/users/me", {
    ...opts
  });
}
export function usersUpdateMe(
  userUpdate: UserUpdate,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/users/me",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: userUpdate
    })
  );
}
export function usersListDocuments(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<{
    status: 200;
    data: DocumentDto[];
  }>("/users/me/documents", {
    ...opts
  });
}
export function usersAddDocuments(
  documentsInput: DocumentsInput,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/users/me/documents/add",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: documentsInput
    })
  );
}
export function usersRemoveDocuments(
  documentsInput: DocumentsInput,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/users/me/documents/remove",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: documentsInput
    })
  );
}
export function userDocumentsListDocumentTypes(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<{
    status: 200;
    data: DocumentType[];
  }>("/documents/types", {
    ...opts
  });
}
export function userDocumentsListDocumentIssuers(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<{
    status: 200;
    data: DocumentIssuer[];
  }>("/documents/issuers", {
    ...opts
  });
}
export function userDocumentsGetDefaultDocuments(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<{
    status: 200;
    data: DocumentDto;
  }>("/documents/default", {
    ...opts
  });
}
export function authSigninManual(
  manualSignInRequest: ManualSignInRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: TokenResponse;
      }
    | {
        status: 400;
        data: ApiErrors & {
          errors?: {
            code?:
              | "registration_required"
              | "confirmation_required"
              | "wrong_password";
          }[];
        };
      }
  >(
    "/auth/signin/manual",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: manualSignInRequest
    })
  );
}
export function authSigninManualConfirm(
  manualSignInConfirmRequest: ManualSignInConfirmRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: TokenResponse;
      }
    | {
        status: 400;
      }
    | {
        status: 404;
      }
  >(
    "/auth/signin/manual/confirm",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: manualSignInConfirmRequest
    })
  );
}
export function authSignin(
  service: "google" | "facebook",
  signInRequest: SignInRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: TokenResponse;
      }
    | {
        status: 400;
        data: ApiErrors & {
          errors?: {
            code?: "registration_required";
          }[];
        };
      }
  >(
    `/auth/signin/${encodeURIComponent(service)}`,
    oazapfts.json({
      ...opts,
      method: "POST",
      body: signInRequest
    })
  );
}
export function authSigninAuth(
  service: "google" | "facebook",
  redirectUri: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    `/auth/signin/${encodeURIComponent(service)}/redirect${QS.query(
      QS.explode({
        redirect_uri: redirectUri
      })
    )}`,
    {
      ...opts
    }
  );
}
export function authRefreshToken(
  refreshTokenRequest: RefreshTokenRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: TokenResponse;
      }
    | {
        status: 403;
      }
  >(
    "/auth/token/refresh",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: refreshTokenRequest
    })
  );
}
export function routerFindRoutes(
  findRoutesRequest: FindRoutesRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: FindRoutesResponse;
      }
    | {
        status: 400;
      }
    | {
        status: 429;
        data: RateLimitResponse;
      }
    | {
        status: 503;
      }
    | {
        status: 524;
      }
  >(
    "/router/findRoutes",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: findRoutesRequest
    })
  );
}
export function routerResults(id: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: SearchResult;
      }
    | {
        status: 404;
      }
  >(
    `/router/results${QS.query(
      QS.explode({
        id
      })
    )}`,
    {
      ...opts
    }
  );
}
export function routerCompleteLocation(
  query: any,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<{
    status: 200;
    data: CompleteLocationResponse;
  }>(
    `/router/location/complete${QS.query(
      QS.explode({
        query
      })
    )}`,
    {
      ...opts
    }
  );
}
export function routerGetLocation(id: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Location;
      }
    | {
        status: 404;
      }
  >(
    `/router/location${QS.query(
      QS.explode({
        id
      })
    )}`,
    {
      ...opts
    }
  );
}
export function routerReportIncorrectInfo(
  reportIncorrectInfoRequest: ReportIncorrectInfoRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/router/reportIncorrectInfo",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: reportIncorrectInfoRequest
    })
  );
}
export function emailSubscriptionsSubscribe(
  emailSubscription: EmailSubscription,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/email-subscriptions",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: emailSubscription
    })
  );
}
export function analyticsPostEvent(
  postEventRequest: PostEventRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchText(
    "/a/event",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: postEventRequest
    })
  );
}
export function analyticsKeepalive(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchText("/a/keepalive", {
    ...opts,
    method: "POST"
  });
}
export function popularDestinationsGetPopularDestinations(
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<{
    status: 200;
    data: Location[];
  }>("/popularDestinations", {
    ...opts
  });
}
export function redirectRedirect(path: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchText(
    `/redirect${QS.query(
      QS.explode({
        path
      })
    )}`,
    {
      ...opts
    }
  );
}
export function visaCheckCheckSingle(
  checkVisaRequest: CheckVisaRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: CheckVisaResponse;
      }
    | {
        status: 400;
      }
  >(
    "/visa-check",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: checkVisaRequest
    })
  );
}
export function visaCheckCheckWorld(
  visaCheckWorldRequest: VisaCheckWorldRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<{
    status: 200;
    data: VisaCheckWorldResponse;
  }>(
    "/visa-check/world",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: visaCheckWorldRequest
    })
  );
}
