<template>
  <div>
    <div class="flex flex-col items-center px-4 py-2">
      <slot>
        <div class="text-xl mt-2">{{ i18n.t("Your passports and visas") }}</div>
      </slot>
      <div class="">{{ i18n.t("No numbers required") }}</div>
      <div class="flex flex-col max-w-full w-full">
        <div
          class="flex lg:flex-row items-end justify-center w-full"
          v-for="(doc, idx) of documents"
          :key="doc.doc?.id ?? 'null'">
          <div v-if="idx" class="w-0 m-1 p-3 mr-4"></div>
          <NewDocumentInput
            ref="documentElements"
            :types="
              idx ? documentTypes ?? [] : passportType ? [passportType] : []
            "
            :issuers="documentIssuers ?? []"
            :selected-issuer="doc.issuer"
            :selected-type="doc.type"
            :show-labels="idx == 0"
            class="mt-4 w-10/12 sm:w-auto md:mr-4"
            @submit="addDocument($event, idx)" />
          <icon
            v-if="idx"
            class="m-1 p-3 font-bold cursor-pointer border rounded border-gray-300"
            icon="fa fa-xmark"
            @click="removeDocument(idx)" />
        </div>
      </div>
      <button ref="addVisaEl" class="my-4" @click="pushDocument">
        {{ i18n.t("Add Visa, documents, etc.") }}
      </button>
    </div>
    <div class="hidden">
      <img v-for="pass of passports" :src="getPassportImgUrl(pass)" alt="" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DocumentDto,
  usersAddDocuments,
  usersRemoveDocuments
} from "../../api/client";
import NewDocumentInput from "./NewDocumentInput.vue";
import { handle200 } from "../../api";
import { computed, ref } from "vue";
import { postEvent } from "../../analytics";
import { useAnimateGreenFlash, useEmitterEvent, useGeoIP } from "../../common";
import {
  documentIssuers,
  documentTypes,
  getPassportImgUrl,
  IssuerSuggestion,
  passportType,
  TypeSuggestion,
  visaType
} from "./common";
import { sift } from "radash";
import { useGlobalStore } from "../../store";
import cuid2 from "@paralleldrive/cuid2";
import { useMainPageEmitter, useMainPageStore } from "../store";
import { useI18n } from "../../locale";

const globalStore = useGlobalStore();
const pageStore = useMainPageStore();
const i18n = useI18n();

const documentElements = ref<InstanceType<typeof NewDocumentInput>[]>();
const addVisaEl = ref();

const flashAddVisa = useAnimateGreenFlash(addVisaEl);
const mainPageEmitter = useMainPageEmitter();

const documents = computed(() => {
  return globalStore.documents.map((doc, idx) => {
    let type: TypeSuggestion, issuer: IssuerSuggestion | undefined;
    if (doc) {
      type = { ...doc.type, text: doc.type.name };
      issuer = { ...doc.issuer, text: doc.issuer.name };
    } else {
      if (idx == 0)
        issuer =
          useGeoIP.value &&
          documentIssuers.value.find(
            i => i.countryCode == useGeoIP.value?.countryCode
          );

      type = idx == 0 ? passportType.value! : visaType.value!;
    }

    return {
      doc,
      type,
      issuer
    };
  });
});

const passports = computed(() =>
  sift(
    globalStore.documents
      .filter(d => d?.type.id == passportType.value?.id)
      .map(p => p?.issuer.countryCode)
  )
);

globalStore.documents = globalStore.documents.filter(d => d);

useEmitterEvent(mainPageEmitter, "highlightAddVisa", () => flashAddVisa());
useEmitterEvent(mainPageEmitter, "highlightLastDocument", () => {
  useAnimateGreenFlash(documentElements.value!.at(-1)!.$el)();
});

async function pushDocument() {
  if (globalStore.documents.length - sift(globalStore.documents).length > 0)
    return;
  const issuerDestination =
    pageStore.search.destination &&
    documentIssuers.value.find(
      i => i.countryCode == pageStore.search.destination?.countryCode
    );

  const hasDestinationVisa = globalStore.documents.find(
    d =>
      d?.type.id == visaType.value?.id &&
      d?.issuer.countryCode == issuerDestination?.countryCode
  );

  if (!hasDestinationVisa && issuerDestination) {
    globalStore.documents.push({
      id: cuid2.createId(),
      type: visaType.value!,
      issuer: issuerDestination!
    });
  } else {
    globalStore.documents.push(null);
  }
}

async function addDocument(d: DocumentDto, idx: number) {
  // otherwise it's gona enter very long recursive constantly updating and recomputing `documents`
  if (
    d.type.id == globalStore.documents.at(idx)?.type.id &&
    d.issuer.id == globalStore.documents.at(idx)?.issuer.id
  )
    return;

  const doc = { ...d, id: cuid2.createId() };

  postEvent("add_document", {
    type: doc.type.canonicalName,
    issuer: doc.issuer.canonicalName
  });

  let prevDoc;
  // When there's only one document in the list handle it differently
  // otherwise when user selects their first document a secod empty one appears
  // out of nowhere.
  if (globalStore.documents.length == 1) {
    prevDoc = globalStore.documents[idx];
    globalStore.documents[idx] = doc;
  } else {
    [prevDoc] = globalStore.documents.splice(idx, 1, doc);
  }

  if (prevDoc) await removeDocumentFromUser(prevDoc);

  if (globalStore.user)
    await handle200(
      usersAddDocuments({
        documents: [{ type: doc.type.id, issuer: doc.issuer.id }]
      })
    );
}

async function removeDocument(idx: number) {
  const doc = globalStore.documents[idx];
  globalStore.documents.splice(idx, 1);
  if (doc) await removeDocumentFromUser(doc);
}

async function removeDocumentFromUser(doc: DocumentDto) {
  if (globalStore.user)
    await handle200(
      usersRemoveDocuments({
        documents: [{ type: doc.type.id, issuer: doc.issuer.id }]
      })
    );
}
</script>

<style scoped></style>
