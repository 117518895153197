import * as Api from "./client";
import { authRefreshToken } from "./client";
import { ApiResponse, WithHeaders } from "oazapfts/lib/runtime";
import { HttpError } from "oazapfts";
import { Mutex } from "../common";
import { useGlobalStore } from "../store";
import { App } from "vue";
import { initGraphql } from "./graphql";

export interface Token {
  access: string;
  refresh: string;
  expiresAt: number;
}

let updateTokenMtx = new Mutex();

export function initApi({ app }: { app: App }) {
  Api.defaults.baseUrl = config.apiUrl;
  Api.defaults.headers = {};

  const customFetch: typeof fetch = async (req, opts) => {
    if (typeof req != "string") throw new Error("not supported");

    opts = opts ?? {};

    let headers: Record<string, any>;
    opts.headers = headers = { ...(opts.headers as any) };

    const store = useGlobalStore();

    headers["Locale"] = store.locale;

    if (store.token) {
      await updateTokenMtx.run(async () => {
        if (store.token && new Date().getTime() > store.token.expiresAt) {
          const resp = await authRefreshToken(
            {
              refreshToken: store.token.refresh
            },
            { fetch }
          );
          if (resp.status == 200) store.setToken(resp.data);
          else if (resp.status == 403) store.setToken(null);
        }
      });

      if (store.token)
        headers["Authorization"] = "Bearer " + store.token.access;
    }

    return fetch(req, opts);
  };
  Api.defaults.fetch = customFetch;

  initGraphql({ app, customFetch });
}

export function httpError(resp: WithHeaders<ApiResponse>) {
  return new HttpError(resp.status, resp.data, resp.headers);
}

export async function handle<
  R extends WithHeaders<ApiResponse>,
  C extends R["status"] & number
>(promise: Promise<R>, status: C[]): Promise<Extract<R, { status: C }>> {
  const resp = await promise;
  if (!status.includes(resp.status as any)) throw httpError(resp);
  return resp as any;
}

export async function handle200<R extends WithHeaders<ApiResponse>>(
  promise: Promise<R>
) {
  return handle(promise, [200]);
}
