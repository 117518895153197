<template>
  <div class="flex flex-row items-end rounded p-1 gap-2">
    <div class="min-w-0 basis-1/2">
      <div v-if="showLabels">{{ i18n.t("Type (passport, visa, etc.):") }}</div>
      <Autocomplete
        v-model="type"
        class="min-w-0 w-full"
        :invalid="!type"
        :show-loader="!props.types"
        :suggestions="props.types"
        :placeholder="i18n.t('Type (passport, visa, etc.)')"
        clear-on-focus
        :suggest-by-distance-threshold="1"
        show-with-empty-input
        suggest-lang-change />
    </div>
    <div class="min-w-0 basis-1/2">
      <div v-if="showLabels">{{ i18n.t("Issuing Country:") }}</div>
      <Autocomplete
        v-model="issuer"
        class="min-w-0 w-full"
        :suggestions="issuers"
        :invalid="!issuer"
        :show-loader="!props.issuers"
        :placeholder="i18n.t('Issuing Country')"
        clear-on-focus
        show-flag
        show-with-empty-input
        suggest-lang-change
        @update:text="issuerText = $event" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { DocumentDto } from "../../api/client";
import Autocomplete from "../../components/Autocomplete.vue";
import {
  documentIssuers,
  documentTypes,
  IssuerSuggestion,
  TypeSuggestion
} from "./common";
import { useI18n } from "../../locale";
import { min, sort } from "radash";
import { levenshteinDistance } from "../../common";

const props = defineProps<{
  types: TypeSuggestion[];
  issuers: IssuerSuggestion[];
  selectedType?: TypeSuggestion | undefined;
  selectedIssuer?: IssuerSuggestion | undefined;
  showLabels?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", doc: DocumentDto): void;
  (e: "update:selectedType", v: TypeSuggestion | undefined): void;
  (e: "update:selectedIssuer", v: IssuerSuggestion | undefined): void;
}>();

const i18n = useI18n();

const type = ref();
const issuer = ref();
watch(
  () => props.selectedType,
  t => (type.value = t),
  { immediate: true }
);
watch(
  () => props.selectedIssuer,
  t => (issuer.value = t),
  { immediate: true }
);
watch(type, t => emit("update:selectedType", t));
watch(issuer, i => emit("update:selectedIssuer", i));

const issuerText = ref("");

const issuers = computed(() => {
  const words = issuerText.value.split(" ").map(w => w.toLowerCase());

  return sort(props.issuers, i => {
    const valueWords = i.text
      .replaceAll("(", "")
      .replaceAll(")", "")
      .split(" ")
      .map(v => v.toLowerCase());

    if (valueWords.find(w => w.startsWith(issuerText.value.toLowerCase())))
      return 0;

    return min(
      words.flatMap(w =>
        valueWords.map(
          v => levenshteinDistance(w, v) / Math.max(w.length, v.length)
        )
      )
    )!;
  });
});

function submit() {
  const typeValue = type.value;
  const issuerValue = issuer.value;
  if (!typeValue || !issuerValue) return;
  emit("submit", {
    type: typeValue,
    issuer: issuerValue
  });
}

watch(type, submit);
watch(issuer, submit);

watch(documentTypes, types => {
  if (type.value)
    type.value = types.find(t => t.id == type.value?.id) ?? type.value;
});
watch(documentIssuers, issuers => {
  if (issuer.value)
    issuer.value = issuers.find(i => i.id == issuer.value?.id) ?? issuer.value;
});
</script>

<style scoped></style>
