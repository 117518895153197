<template>
  <div class="flex flex-col">
    <RouteComponent
      v-for="route of props.routes"
      :route="route"
      :key="route.id"
      class="even:bg-gray-200 border-gray-300 border-x border-b first:border-t" />
  </div>
</template>

<script lang="ts" setup>
import RouteComponent from "./Route.vue";
import { RouteExt } from "../store";

const props = defineProps<{
  routes: RouteExt[];
}>();
</script>

<style scoped></style>
