<template>
  <div class="container-widget p-7 bg-white">
    <form v-if="!submited" class="flex flex-col gap-2" @submit.prevent="submit">
      <span class="text-lg font-bold">{{
        i18n.t("What's the purpose of your visit?")
      }}</span>
      <label>
        <input
          type="radio"
          name="purpose"
          v-model="purpose"
          value="work"
          required />
        {{ i18n.t("Work") }}
      </label>
      <label>
        <input type="radio" name="purpose" v-model="purpose" value="tourism" />
        {{ i18n.t("Tourism") }}
      </label>
      <label>
        <input type="radio" name="purpose" v-model="purpose" value="other" />
        {{ i18n.t("Other") }}
      </label>

      <span class="mt-5 text-lg font-bold">
        {{ i18n.t("Do you have an official income?") }}
      </span>
      <label>
        <input
          type="radio"
          name="income"
          v-model="income"
          :value="true"
          required />
        {{ i18n.t("Yes") }}
      </label>
      <label>
        <input v-model="income" :value="false" name="income" type="radio" />
        {{ i18n.t("No") }}
      </label>
      <button class="mt-5">{{ i18n.t("Submit") }}</button>
    </form>
    <div v-else>
      <p class="font-bold text-lg">{{ i18n.t("Thanks for your interest.") }}</p>
      <p class="mt-1">
        {{
          i18n.t(
            "We are working on implementing visa-related features. Your feedback is very important."
          )
        }}
      </p>
      <p class="mt-2 mb-3">
        <router-link to="/index/login">{{ i18n.t("Sign up") }}</router-link>
        {{ i18n.t("and follow us on") }}
        <a href="https://twitter.com/llcpassportal" target="_blank">{{
          i18n.t("Twitter")
        }}</a>
        {{
          i18n.t(
            "if you want to receive an update when the feature will be added."
          )
        }}
      </p>
    </div>
    <button
      class="mt-2 w-full"
      :class="{ 'bg-gray-300': !submited }"
      @click="$emit('close')">
      {{ i18n.t("Close") }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { postEvent } from "../../analytics";
import { ref } from "vue";
import { type Location } from "../../api/client";
import { useGlobalStore } from "../../store";
import { useI18n } from "../../locale";

const props = defineProps<{ destination: Location }>();

const store = useGlobalStore();
const i18n = useI18n();

const purpose = ref<string>();
const income = ref<string>();

const submited = ref(false);

function submit() {
  submited.value = true;
  postEvent("visa_survey.submit", {
    answers: {
      purpose: purpose.value,
      hasIncome: income.value
    },
    destination: props.destination,
    documents: store.documents
  });
}

postEvent("visa_survey.open", {
  destination: props.destination,
  documents: store.documents
});
</script>

<style lang="sass" scoped>
label, label > input
  @apply cursor-pointer

label
  @apply flex items-center gap-3
</style>
