<template>
  <Overlay v-model="show" route class="z-[2]">
    <div
      class="h-screen max-h-screen w-screen max-w-screen overflow-y-auto flex justify-center items-center"
      @click="router.back()">
      <div
        @click.stop
        ref="el"
        class="my-auto flex flex-col gap-3 container-widget bg-white p-10 md:w-auto md:min-w-[400px]">
        <div class="font-bold text-xl">{{ i18n.t("Your story") }}</div>
        {{ i18n.t("I went from") }}
        <div v-if="fromErr" class="text-red-500 text-sm">
          {{ i18n.t("Select country") }}
        </div>
        <Autocomplete
          v-model="from"
          :suggestions="countries"
          :suggest-by-substring="false"
          :suggest-by-distance-threshold="1"
          show-flag
          :placeholder="i18n.t('Country (e.g. United States)')" />
        {{ i18n.t("Through") }}
        <div class="self-stretch">
          <div class="flex flex-col gap-3 self-stretch">
            <div
              v-for="(loc, idx) in transits"
              class="flex flex-row gap-3 items-center w-full">
              <Autocomplete
                class="grow"
                :model-value="loc"
                :suggestions="countries"
                :suggest-by-substring="false"
                :suggest-by-distance-threshold="1"
                :placeholder="i18n.t('Country (e.g. United States)')"
                show-flag
                @update:model-value="
                  updateTransit(idx, $event as CountrySuggestion)
                " />
              <div
                class="flex flex-row min-w-[1.75rem] mr-1 cursor-pointer self-stretch items-center justify-center"
                @click="transits.splice(idx, 1)">
                <icon icon="xmark" class="h-5" />
              </div>
            </div>
            <div class="flex flex-row gap-3 items-center w-full">
              <Autocomplete
                disabled
                class="w-full"
                ref="transitPlaceholder"
                :model-value="transitPlaceholder"
                :suggestions="countries"
                :suggest-by-substring="false"
                :suggest-by-distance-threshold="1"
                @update:model-value="
                  addTransit($event as CountrySuggestion | undefined)
                "
                show-flag
                :placeholder="i18n.t('Country (e.g. United States)')" />
              <div
                class="flex flex-row min-w-[1.75rem] mr-1 cursor-pointer self-stretch items-center justify-center"
                @click="transits.push(null)">
                <icon icon="plus" class="h-5" />
              </div>
            </div>
          </div>
        </div>
        {{ i18n.t("To") }}
        <div v-if="toErr" class="text-red-500 text-sm">
          {{ i18n.t("Select country") }}
        </div>
        <Autocomplete
          v-model="to"
          :suggestions="countries"
          :suggest-by-substring="false"
          :suggest-by-distance-threshold="1"
          show-flag
          :placeholder="i18n.t('Country (e.g. United States)')" />

        <div class="flex flex-col gap-3" v-if="showDate">
          {{ i18n.t("Travel date") }}
          <div v-if="dateErr" class="text-red-500 text-sm">
            {{ i18n.t("Select date") }}
          </div>
          <VueDatePicker
            v-model="date"
            :clearable="false"
            :enable-time-picker="false"
            auto-apply
            required />
        </div>

        <div v-if="showDocuments" class="flex flex-col gap-3">
          {{ i18n.t("Documents") }}
          <div v-if="emptyDocsErr" class="text-red-500 text-sm">
            {{ i18n.t("Please add at least one document") }}
          </div>
          <div v-if="noPassportErr" class="text-red-500 text-sm">
            {{ i18n.t("Please add at least one Passport") }}
          </div>
          <div v-for="(d, idx) in documents" :key="d.id" class="flex flex-row">
            <NewDocumentInput
              class="min-w-0 grow"
              :types="documentTypes"
              :issuers="documentIssuers"
              :selected-type="idx == 0 ? passportType : undefined"
              @submit="d.d = $event" />
            <div
              v-if="idx != 0"
              class="flex flex-row w-7 mr-1 cursor-pointer self-stretch items-center justify-center"
              @click="documents.splice(idx, 1)">
              <icon :icon="idx != 0 ? 'xmark' : ''" class="h-5" />
            </div>
          </div>
          <button @click="documents.push({ id: cuid2.createId() })">
            {{ i18n.t("Add document") }}
          </button>
        </div>

        <div v-if="textErr" class="text-red-500 text-sm">
          {{ i18n.t("Please write a story") }}
        </div>
        <div class="self-stretch">
          <textarea
            v-if="showText"
            v-model="text"
            placeholder="Write your story here"
            cols="30"
            rows="10"
            class="border-[2px] border-gray-400 rounded w-full"></textarea>
        </div>
        <button @click="next">
          {{ showText ? i18n.t("Submit") : i18n.t("Next") }}
        </button>
      </div>
    </div>
    <Loader v-if="createStory.fetching.value" />
    <Overlay
      :model-value="httpError != 0"
      @update:model-value="$event ? undefined : (httpError = 0)">
      <RateLimitAlert :status-code="httpError" />
    </Overlay>
  </Overlay>
</template>

<script setup lang="ts">
import {
  Ref,
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  ref,
  watch
} from "vue";
import NewDocumentInput from "../../main_page/documents/NewDocumentInput.vue";
import { useI18n } from "../../locale";
import { useScroll, watchOnce } from "@vueuse/core";
import {
  documentIssuers,
  documentTypes,
  passportType
} from "../../main_page/documents/common";
import { DocumentDto } from "../../api/client";
import { useMutation, useQuery } from "@urql/vue";
import { graphql } from "../../api/gql";
import Autocomplete, { Suggestion } from "../../components/Autocomplete.vue";
import { Country } from "../../api/gql/graphql";
// use overlay in here to keep share story alive
import Overlay from "../../components/overlay/Overlay.vue";
import { useGlobalStore } from "../../store";
import { useRouter } from "vue-router";
import Loader from "../../components/Loader.vue";
import RateLimitAlert from "../../main_page/RateLimitAlert.vue";
import { sift } from "radash";
import cuid2 from "@paralleldrive/cuid2";

type CountrySuggestion = Country & Suggestion;

const el = ref();
const i18n = useI18n();
const router = useRouter();
const store = useGlobalStore();
const toClear: { ref: Ref; def: any }[] = [];
const show = ref(true);
onActivated(() => (show.value = true));
onDeactivated(() => (show.value = false));
const scroll = useScroll(el);

const showDate = clearable(ref(false), false);
const showDocuments = clearable(ref(false), false);
const showText = clearable(ref(false), false);

const from = clearable(ref<CountrySuggestion>(), undefined);
const to = clearable(ref<CountrySuggestion>(), undefined);
const fromErr = clearable(ref(false), false);
const toErr = clearable(ref(false), false);
const transits = clearable(ref<(CountrySuggestion | null)[]>([]), () => []);
const transitPlaceholder = clearable(ref(), undefined);

const date = clearable(ref<Date>(), undefined);
const dateErr = clearable(ref(false), false);

const documents = clearable(
  ref<{ id: string; d?: DocumentDto }[]>([{ id: cuid2.createId() }]),
  () => [{ id: cuid2.createId() }]
);
const emptyDocsErr = clearable(ref(false), false);
const noPassportErr = clearable(ref(false), false);

const text = clearable(ref(""), "");
const textErr = clearable(ref(false), false);

const httpError = ref(0);

const getCountries = useQuery({
  query: graphql(`
    query GetCountries {
      countries {
        id
        name
        code
      }
    }
  `)
});
watch(getCountries.error, e => console.error(e));

const countries = computed(
  () =>
    getCountries.data.value?.countries.map(
      c =>
        ({
          ...c,
          text: c.name,
          countryCode: c.code
        }) satisfies CountrySuggestion
    ) ?? []
);

function addTransit(loc: CountrySuggestion | undefined) {
  if (loc?.id) {
    transits.value.push(loc);
    transitPlaceholder.value = null;
  }
}

function updateTransit(idx: number, loc: CountrySuggestion | undefined) {
  if (loc?.id) {
    transits.value[idx] = loc;
  } else {
    transits.value.splice(idx, 1);
  }
}

function scrollDown() {
  nextTick().then(() => (scroll.y.value = 1000));
}

function next() {
  if (from.value && to.value) {
    if (!showDate.value) {
      showDate.value = true;
      scrollDown();
      return;
    }
  } else {
    if (!from.value) {
      fromErr.value = true;
      watchOnce(from, () => (fromErr.value = false));
    }
    if (!to.value) {
      toErr.value = true;
      watchOnce(to, () => (toErr.value = false));
    }
  }

  if (date.value) {
    if (!showDocuments.value) {
      showDocuments.value = true;
      scrollDown();
      return;
    }
  } else if (showDate.value) {
    dateErr.value = true;
    watchOnce(date, () => (dateErr.value = false));
  }

  const noPassport = !documents.value.find(
    d => d.d?.type.canonicalName == "Passport"
  );
  if (documents.value.length && !noPassport) {
    if (!showText.value) {
      showText.value = true;
      scrollDown();
      return;
    }
  } else if (showDocuments.value) {
    if (!documents.value.length) {
      emptyDocsErr.value = true;
    } else {
      noPassportErr.value = true;
    }
    watchOnce(
      documents,
      () => (noPassportErr.value = emptyDocsErr.value = false),
      { deep: true }
    );
  }

  if (text.value && showText.value) {
    submit();
  } else if (showText.value) {
    textErr.value = true;
    watchOnce(text, () => (textErr.value = false));
  }
}

const createStory = useMutation(
  graphql(`
    mutation CreateStory($input: CreateStoryInput!) {
      createStory(input: $input) {
        claimCode
      }
    }
  `)
);

function submit() {
  createStory
    .executeMutation({
      input: {
        body: text.value,
        date: {
          year: date.value?.getFullYear()!
        },
        stops: [from.value!, ...sift(transits.value), to.value!].map(t => t.id),
        documents: documents.value
          .filter(d => d.d != undefined)
          .map(d => ({
            type: d.d!.type.id,
            issuer: d.d!.issuer.id
          }))
      }
    })
    .then(r => {
      if (r.error) {
        httpError.value = 1;
        console.error(r.error);
      } else {
        clear();
        if (
          r.data?.createStory.claimCode &&
          !store.storiesClaimCodes.includes(r.data.createStory.claimCode)
        ) {
          store.storiesClaimCodes.push(r.data.createStory.claimCode);
        }
        // if I just replace the route scroll lock stays locked forever
        router.back();
        watchOnce(router.currentRoute, () =>
          router.push("/stories/shareStory/success")
        );
      }
    });
}

function clearable<R extends Ref>(ref: R, def: () => R["value"]): R;
function clearable<R extends Ref>(ref: R, def: R["value"]): R;

function clearable<R extends Ref>(ref: R, def: R["value"]): R {
  toClear.push({ ref, def });
  return ref;
}

function clear() {
  toClear.forEach(
    c => (c.ref.value = typeof c.def == "function" ? c.def() : c.def)
  );
}
</script>

<style scoped></style>
