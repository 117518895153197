<template>
  <div class="flex flex-col relative">
    <div
      v-if="errorMessage"
      class="absolute -top-3 left-2 text-sm text-red-500 bg-white truncate max-w-[90%]"
      v-text="errorMessage"></div>
    <input
      v-model="value"
      :class="errorMessage ? 'error' : ''"
      v-bind="attrs" />
  </div>
</template>

<script lang="ts" setup>
import { computed, useAttrs } from "vue";

defineOptions({
  inheritAttrs: false
});

const attrs = useAttrs();
const props = withDefaults(
  defineProps<{
    errorMessage?: string;
    modelValue?: string;
  }>(),
  {
    modelValue: ""
  }
);
const emit = defineEmits<{
  (e: "update:modelValue", v: string): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit("update:modelValue", v);
  }
});
</script>

<style lang="sass" scoped>
.error
  @apply outline-2 outline-red-400
  animation: blink .4s

@keyframes blink
  50%
    @apply outline-8
</style>
