<template>
  <div class="flex flex-col not-first:ml-7 px-1 border-gray-300">
    <div class="flex items-start gap-x-2" @click="expand = !expand">
      <div class="flex flex-col self-stretch py-1">
        <icon class="w-3 self-center text-gray-400" icon="fa fa-circle-dot" />
        <div
          class="grow self-center text-gray-400"
          style="border-right: 4px dotted"></div>
        <icon
          :class="colorBySeverity(segment.check?.severity)"
          :icon="iconBySeverity(segment.check?.severity)"
          class="" />
      </div>
      <CarrierLogo v-if="segment.carrier" :code="segment.carrier?.code" />
      <div class="flex flex-col w-full">
        <div class="flex items-center gap-x-2">
          <div>{{ segment.from.name }} ({{ segment.from.countryName }}</div>
          <Flag class="h-5" :country-code="segment.from.countryCode" />
          )
        </div>
        <div v-if="groupKind != 'manual'" class="text-sm font-bold">
          {{ formatDate(segment.departure) }}
        </div>
        <div v-if="segment.duration" class="text-sm text-gray-500">
          {{ i18n.t("Travel time:") }}
          {{ formatTime(segment.duration) }}
        </div>
        <div class="flex items-center gap-x-2">
          <div>{{ segment.to.name }} ({{ segment.to.countryName }}</div>
          <Flag :country-code="segment.to.countryCode" class="h-5" />
          )
        </div>
        <div v-if="groupKind != 'manual'" class="text-sm font-bold">
          {{ formatDate(segment.arrival) }}
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div v-if="segment.carrier" class="text-sm text-gray-500 my-2">
        {{ segment.carrier.name }}
      </div>
      <div class="grow"></div>
      <div
        v-if="segment.check?.severity == 'fatal' && !hasVisa(segment.to)"
        class="flex flex-col gap-2">
        <button class="bg-orange-300" @click="addVisa(segment.to)">
          {{ i18n.t("I have a visa") }}
        </button>
        <button
          v-element-visibility="() => postEvent('visa_survey.proposed')"
          @click="visaSurvey = segment.to"
          class="fancy-gradient text-white font-bold">
          {{ i18n.t("I want a visa!") }}
        </button>
      </div>
    </div>
    <div
      v-if="segment.check"
      :class="expand ? '' : 'h-[4.2em] relative overflow-hidden'"
      class="md:ml-4 border border-gray-300 rounded flex flex-col items-start gap-y-1 py-1 text-base mt-2">
      <div class="w-full h-ful not-first:mt-4">
        <p
          v-for="msg of segment.check.messages"
          :class="[...gradientBySeverity(msg.severity)]"
          class="rounded px-2 w-0 min-w-full"
          v-html="msg.text"></p>
      </div>
      <div
        v-if="!expand"
        class="absolute w-full h-full text-center bottom-0 font-bold text-blue-500 blured flex flex-row items-end justify-center cursor-pointer"
        @click="expand = !expand">
        <span>{{ i18n.t("Show more") }}</span>
      </div>
    </div>
    <div v-if="segment.layover" class="py-2">
      <hr />
      <div class="py-2" style="font-size: 0.9em">
        {{ i18n.t("Layover:") }} {{ formatTime(segment.layover) }}
      </div>
      <hr />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { postEvent } from "../../analytics";
import { CheckResult, Location, Segment, SegmentGroup } from "../../api/client";
import { useI18n } from "../../locale";
import { useGlobalStore } from "../../store";
import { useMainPageEmitter } from "../../main_page/store";
import { documentIssuers, visaType } from "../../main_page/documents/common";
import { useRouter } from "vue-router";
import cuid2 from "@paralleldrive/cuid2";
import { sleep } from "radash";
import Flag from "../../components/Flag.vue";
import CarrierLogo from "../../components/CarrierLogo.vue";

export interface Layover {
  layover?: number;
}

defineProps<{
  groupKind: SegmentGroup["kind"];
  segment: Segment & Layover;
}>();

const i18n = useI18n();
const globalStore = useGlobalStore();
const router = useRouter();

const expand = ref(false);
const visaSurvey = ref<Location>();
const mainPageEmitter = useMainPageEmitter();

function formatDate(date: string) {
  return new Date(date).toLocaleString(globalStore.locale, {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit"
  });
}

function formatTime(duration: number) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.round((duration / 60) % 60);
  return `${hours ? hours + "h " : ""}${minutes}m`;
}

function iconBySeverity(severity: CheckResult["severity"] | undefined): string {
  if (severity == "fatal") return "fa-circle-xmark";
  if (severity == "warning") return "fa-triangle-exclamation";
  return "fa-circle-check";
}

function colorBySeverity(
  severity: CheckResult["severity"] | undefined
): string {
  if (severity == "fatal") return "text-red-600";
  if (severity == "warning") return "text-amber-600";
  return "text-green-600";
}

function gradientBySeverity(
  severity: CheckResult["severity"] | undefined
): string[] {
  if (severity == "fatal") return ["bg-rose-100"];
  if (severity == "warning") return ["bg-orange-100"];
  return [];
}

function hasVisa(dest: Location): boolean {
  return (
    globalStore.documents.find(
      d =>
        d?.type.id == visaType.value?.id &&
        d?.issuer.countryCode == dest.countryCode
    ) != null
  );
}

function addVisa(dest: Location) {
  router.back();
  globalStore.documents.push({
    id: cuid2.createId(),
    type: visaType.value!,
    issuer: documentIssuers.value.find(i => i.countryCode == dest.countryCode)!
  });
  mainPageEmitter.emit("scrollToDocuments");
  sleep(500).then(() => mainPageEmitter.emit("highlightLastDocument"));
}
</script>

<style lang="sass" scoped>
.blured
  background: linear-gradient(transparent, transparent 5%, white 100%)
</style>
