<template>
  <div :class="{ fullscreen: show && isMobile }" :show="show">
    <div
      class="flex flex-col relative h-full"
      :style="phoneStyle"
      @focusin="show = true">
      <div
        :style="{ display: props.invalid && invalidMessage ? '' : 'none' }"
        class="px-1 text-sm text-red-500 absolute -top-3 left-2 bg-white truncate max-w-[95%]"
        :class="{
          'top-0': show && isMobile
        }">
        {{ props.invalidMessage }}
      </div>
      <input
        :disabled="disabled"
        v-model="text"
        class="md:h-full skiptranslate"
        :class="{
          'flag-icon': props.showFlag && modelValue?.countryCode,
          'input-invalid blink': props.invalid,
          'm-1 mt-3': show && isMobile
        }"
        :style="{
          'background-image':
            props.showFlag && modelValue?.countryCode
              ? `url(/flags/${modelValue.countryCode}.svg)${
                  modelValue.secondCountryCode ? ', url(/flags/EU.svg)' : ''
                }`
              : 'none',
          'text-indent':
            props.showFlag && modelValue?.countryCode
              ? modelValue?.secondCountryCode
                ? '5em'
                : '2.5em'
              : ''
        }"
        type="text"
        ref="input"
        :placeholder="placeholder"
        @keydown.down.prevent="selectedIdx++"
        @keydown.up.prevent="selectedIdx--"
        @keydown.enter.prevent="enter()"
        @blur="show = false" />
      <div
        v-show="show && (suggestions.length || showLoader)"
        class="completion-list skiptranslate">
        <div v-if="!showLangSuggestion && showLoader">
          <div class="h-20 flex items-center justify-center">
            <icon
              class="text-gray-700 animate-spin h-7"
              icon="fas fa-spinner" />
          </div>
        </div>
        <div
          v-else-if="showLangSuggestion"
          class="flex flex-col items-center p-5">
          <icon class="h-10 text-blue-400" icon="language" />
          <div class="flex gap-2 border-[5px] border-blue-400 p-3 rounded-md">
            <FlagIcon
              v-for="lang in suggestLang"
              :countryCode="lang.code == 'ru' ? 'RU2' : lang.countryCode"
              class="h-10 cursor-pointer"
              @mousedown="store.selectedLocale = lang.code" />
          </div>
        </div>
        <div v-show="!showLangSuggestion && !showLoader && suggestions.length">
          <template v-for="(el, idx) of suggestions" :key="el.text">
            <div
              :class="{ 'bg-blue-100': selectedIdx == idx }"
              class="cursor-pointer whitespace-nowrap z-10 hover:bg-blue-100 hover:text-blue-600 transition-colors"
              @mousedown.prevent="click(idx)">
              <slot :suggestion="el as Suggestion & any">
                <div class="flex items-center gap-x-1 px-1">
                  <FlagIcon
                    v-if="showFlag && el.countryCode"
                    :country-code="el.countryCode!"
                    class="h-[1.35em]" />
                  <FlagIcon
                    v-if="showFlag && el.secondCountryCode"
                    :country-code="el.secondCountryCode!"
                    class="h-[1.35em]" />
                  {{ el.text }}
                </div>
              </slot>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  nextTick,
  onScopeDispose,
  ref,
  watch,
  watchEffect
} from "vue";
import FlagIcon from "../components/Flag.vue";
import {
  breakpointsTailwind,
  useBreakpoints,
  useFocus,
  useScrollLock
} from "@vueuse/core";
import { useGlobalStore } from "../store";
import langs from "../../locales/langs.json";
import { levenshteinDistance } from "../common";

export interface Suggestion {
  text: string;
  countryCode?: string | null;
  secondCountryCode?: string | null;
}

const emit = defineEmits<{
  (e: "update:text", value: string): void;
  (e: "update:modelValue", value: Suggestion | null): void;
  (e: "submit"): void;
  (e: "show", show: boolean): void;
}>();

const props = withDefaults(
  defineProps<{
    modelValue: Suggestion | null;
    suggestions: Suggestion[];
    showWithEmptyInput?: boolean;
    clearOnFocus?: boolean;
    placeholder?: string;
    invalid?: boolean;
    invalidMessage?: string;
    showLoader?: boolean;
    showFlag?: boolean;
    suggestLangChange?: boolean;
    suggestBySubstring?: boolean;
    // percentage difference threshold value between 0 and 1
    suggestByDistanceThreshold?: number;
    disabled?: boolean;
  }>(),
  {
    modelValue: null,
    filterSuggestions: true
  }
);

const store = useGlobalStore();
const text = ref("");
const input = ref<HTMLInputElement>();
const show = useFocus(input).focused;
const selectedIdx = ref(-1);
const suggestLang = ref<{ code: string; countryCode: string }[]>([]);

const isMobile = useBreakpoints(breakpointsTailwind).smallerOrEqual("sm");
const scrollLock = useScrollLock(document.body);
const viewportHeight = ref(window.visualViewport?.height);
// All this to make fullscreen work on phones.
// When the keyboard is open the actual viewport doesn't change
// so we have to change the size of the element to have
// intuintive scrolling of completion suggestions. Otherwise
// last elements would be hidden by the keyboard and user
// would have to release scroll and then scroll the whole page
// again and get confused.
//
// Almost works but if the user scrolls to the bottom of the list
// and then then releases scroll they still can scroll the whole page
// even further down and then get confused.
const phoneStyle = computed(() =>
  show.value && isMobile.value ? { height: viewportHeight.value + "px" } : {}
);

const suggestions = computed(() => {
  if (!text.value)
    if (props.showWithEmptyInput) return props.suggestions;
    else return [];

  if (props.suggestBySubstring) {
    return props.suggestions.filter(
      s =>
        s.text.toLowerCase().includes(text.value.toLowerCase()) &&
        s.text != text.value
    );
  }

  if (props.suggestByDistanceThreshold != undefined) {
    return props.suggestions
      .filter(s => s.text != text.value)
      .map(v => ({
        ...v,
        distance: v.text.toLowerCase().startsWith(text.value.toLowerCase())
          ? 0
          : levenshteinDistance(
              v.text.toLowerCase(),
              text.value.toLowerCase()
            ) / Math.max(v.text.length, text.value.length)
      }))
      .sort((a, b) => {
        return a.distance - b.distance;
      })
      .filter(v => v.distance < props.suggestByDistanceThreshold!);
  }

  return props.suggestions.filter(s => s.text != text.value);
});

const showLangSuggestion = computed(
  () =>
    props.suggestLangChange &&
    suggestLang.value.length != 0 &&
    !suggestLang.value.find(l => l.code == store.locale)
);

watchEffect(() => {
  if (!show.value) selectedIdx.value = -1;
  if (selectedIdx.value < -1) selectedIdx.value = -1;
  if (selectedIdx.value >= suggestions.value.length)
    selectedIdx.value = suggestions.value.length - 1;
});

watch(
  () => props.modelValue,
  v => {
    if (v) text.value = v.text;
    else text.value = "";
  },
  { immediate: true }
);

function mapLocaleFlag(locale: string): { code: string; countryCode: string } {
  return langs.find(l => l.code == locale)!;
}

watch(text, text => {
  emit("update:text", text);
  if (props.suggestLangChange) {
    if (text.length == 0) suggestLang.value = [];

    for (let i = 0; i < text.length; i++) {
      const char = text.charCodeAt(i);

      if (0x0400 <= char && char <= 0x04ff) {
        suggestLang.value = ["uk", "ru"].map(mapLocaleFlag);
        break;
      } else if (0x0900 <= char && char <= 0x097f) {
        suggestLang.value = ["hi"].map(mapLocaleFlag);
        break;
      }

      suggestLang.value = ["en"].map(mapLocaleFlag);
    }
  }
});

let prevText = "";
let textChangedWhileShown = false;
let stopWatchingTextChange = () => {};
watch(show, show => {
  if (props.clearOnFocus) {
    if (show) {
      textChangedWhileShown = false;
      prevText = text.value;
      text.value = "";
      stopWatchingTextChange = watch(
        text,
        () => (textChangedWhileShown = true)
      );
    } else {
      stopWatchingTextChange();
      if (!textChangedWhileShown) {
        text.value = prevText;
      }
    }
  }

  if (!show && props.modelValue?.text != text.value) {
    text.value = props.modelValue?.text ?? "";
  }
  emit("show", show);
});

function enter() {
  if (selectedIdx.value == -1) emit("submit");
  else {
    emit("update:modelValue", suggestions.value[selectedIdx.value]);
    selectedIdx.value = -1;
  }
  if (isMobile) nextTick().then(() => (show.value = false));
}

function click(idx: number) {
  emit("update:modelValue", suggestions.value[idx]);
  if (isMobile) nextTick().then(() => (show.value = false));
}

function viewportResizeListner() {
  viewportHeight.value = window.visualViewport?.height;
}

window.visualViewport?.addEventListener("resize", viewportResizeListner);

onScopeDispose(() => {
  window.visualViewport?.removeEventListener("resize", viewportResizeListner);
});

watch([show, isMobile], ([show, isSm]) => {
  scrollLock.value = show && isSm;
});
</script>

<style lang="sass" scoped>
@keyframes blink_input_opacity_to_prevent_scrolling_when_focus
  0%
    opacity: 0
  100%
    opacity: 1

.input-focused
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s

.fullscreen
  z-index: 2
  @apply w-screen h-screen fixed top-0 left-0 input-focused bg-white

.completion-list
  @apply w-full flex flex-col border-2 rounded-lg shadow leading-10 lg:leading-8 bg-white overflow-hidden overflow-y-auto grow

@screen md
  .completion-list
    @apply absolute max-h-60 lg:max-h-96 top-full z-50 grow-0
</style>

<style scoped>
.blink {
  animation: blink 0.4s;
}

@keyframes blink {
  50% {
    outline-width: 10px;
  }
  to {
  }
}

.flag-icon {
  background-repeat: no-repeat;
  background-size: 2em;
  background-position-y: center;
  background-position-x: 0.5em, 3em;
}

@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input-focused {
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}
</style>
