<template>
  <span>{{ amount.toFixed(0) }} {{ currency }}</span>
</template>

<script lang="ts" setup>
import { useGlobalStore } from "../../store";
import { exchangeRates } from "./currency";
import { reactiveComputed } from "@vueuse/core";
import { toRefs } from "vue";

const props = defineProps<{ amount: number }>();

const store = useGlobalStore();

const { amount, currency } = toRefs(
  reactiveComputed(() => {
    const rate = exchangeRates.value[store.currency];
    if (!rate) return { amount: props.amount, currency: "EUR" };
    return { amount: props.amount * rate, currency: store.currency };
  })
);
</script>

<style scoped></style>
