<template>
  <div class="w-12">
    <Autocomplete
      v-model="currency"
      :suggest-by-substring="false"
      :suggestions="filteredSuggestions"
      class="autocomplete"
      clear-on-focus
      show-with-empty-input
      @update:text="text = $event">
      <template v-slot="{ suggestion }">
        <div class="w-full flex py-1 px-4 cursor-pointer">
          <div class="w-14" v-text="suggestion.currencyCode"></div>
          {{ suggestion.currencyName }}
        </div>
      </template>
    </Autocomplete>
  </div>
</template>

<script lang="ts" setup>
import Autocomplete from "../Autocomplete.vue";
import { computed, ref } from "vue";
import { currencies } from "./currency";
import { useGlobalStore } from "../../store";

const store = useGlobalStore();

const text = ref("");

const suggestions = computed(() => {
  return currencies.map(c => ({
    currencyCode: c.currencyCode,
    currencyName: c.currencyName,
    text: c.currencyCode
  }));
});
const filteredSuggestions = computed(() =>
  suggestions.value.filter(c =>
    [c.currencyCode, c.currencyName].find(t =>
      t.toLowerCase().includes(text.value.toLowerCase())
    )
  )
);

const currency = computed({
  get() {
    return suggestions.value.find(c => c.currencyCode == store.currency)!;
  },
  set(v) {
    store.selectedCurrency = v.currencyCode;
  }
});
</script>

<style lang="sass" scoped>
.autocomplete
  :deep(input)
    @apply text-lg text-gray-600 font-semibold

  &[show=false] :deep(input)
    @apply outline-0 px-0

  :deep(.completion-list)
    @apply md:w-72 md:right-0
</style>
