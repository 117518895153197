<template>
  <component :is="text" />
</template>

<script lang="ts" setup>
import { computedAsync } from "@vueuse/core";
import { useGlobalStore } from "../store";
import { DEFAULT_LOCALE } from "../locale";

const props = defineProps<{
  text: Record<string, () => Promise<any>>;
}>();

const store = useGlobalStore();

const text = computedAsync(async () => {
  function find(locale: string) {
    return Object.entries(props.text).find(([k]) =>
      k.endsWith(`${locale}.md`)
    )?.[1];
  }

  const { default: component } = await (
    find(store.locale) ?? find(DEFAULT_LOCALE)!
  )();
  return component;
});
</script>

<style scoped></style>
