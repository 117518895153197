import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faArrowRightArrowLeft,
  faBars,
  faBuilding,
  faBus,
  faCar,
  faCertificate,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleDot,
  faCircleInfo,
  faCircleXmark,
  faClock,
  faDollarSign,
  faEnvelope,
  faGlobe,
  faLanguage,
  faLocationDot,
  faMinus,
  faPassport,
  faPersonWalkingLuggage,
  faPlane,
  faPlaneDeparture,
  faRetweet,
  faSpinner,
  faTaxi,
  faTrain,
  faTrainTram,
  faTrash,
  faTriangleExclamation,
  faXmark,
  faCaretUp,
  faCaretDown,
  faShare,
  faCheck,
  faIdCard,
  faFileLines,
  faPlus,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import {
  faBitcoin,
  faEthereum,
  faMonero,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faBuilding,
  faGlobe,
  faLocationDot,
  faTrash,
  faPlaneDeparture,
  faPlane,
  faCar,
  faTaxi,
  faBus,
  faTrain,
  faPersonWalkingLuggage,
  faTrainTram,
  faArrowRight,
  faCircleInfo,
  faTelegram,
  faXmark,
  faCircleXmark,
  faTriangleExclamation,
  faCircleCheck,
  faLanguage,
  faSpinner,
  faCircleDot,
  faChevronLeft,
  faChevronRight,
  faArrowRightArrowLeft,
  faRetweet,
  faDollarSign,
  faClock,
  faCertificate,
  faPassport,
  faMinus,
  faEnvelope,
  faLocationDot,
  faBitcoin,
  faEthereum,
  faMonero,
  faChevronDown,
  faChevronUp,
  faBars,
  faCaretUp,
  faCaretDown,
  faShare,
  faCheck,
  faIdCard,
  faFileLines,
  faPlus,
  faStar
);
