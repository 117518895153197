<template>
  <div
    class="container-widget p-7 bg-white lg:w-1/2 w-full max-h-full overflow-y-auto relative">
    <icon
      class="absolute text-gray-500 w-6 h-6 right-8 top-8 cursor-pointer"
      icon="circle-xmark"
      @click="$router.back()"></icon>
    <MdTranslate v-if="!props.faq" :text="help" />
    <MdTranslate v-else :text="faq" />
    <button class="bg-gray-300 w-full mt-6" @click="$router.back()">
      {{ i18n.t("Close") }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "../locale";
import MdTranslate from "./MdTranslate.vue";

const props = defineProps<{
  faq?: boolean;
}>();

const i18n = useI18n();

const help = import.meta.glob("@locales/help/*");
const faq = import.meta.glob("@locales/faq/*");
</script>
