<template>
  <div
    class="relative"
    v-on-click-outside="() => (isHovered = false)"
    @click.stop="isHovered = true"
    @mouseleave="isHovered = false">
    <div :class="{ invisible: show }" @mouseenter="isHovered = true">
      <slot :isHovered="false"></slot>
    </div>
    <div
      v-if="show"
      class="absolute left-0 top-0 container-widget bg-white border-white animation z-1">
      <slot :isHovered="true"></slot>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@keyframes animation
  from
    @apply p-0
  to
    @apply p-1.5

.animation
  animation: animation .2s forwards
</style>

<script setup lang="ts">
import { computed, ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";

const props = defineProps<{
  disable?: boolean;
}>();

const isHovered = ref(false);
const show = computed(() => !props.disable && isHovered.value);
</script>
