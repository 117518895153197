import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createApp } from "vue";
import * as VueRouter from "vue-router";
import App from "./App.vue";
import "./icons";
import "./index.sass";
import MainPage from "./main_page/MainPage.vue";
import SsoCallback from "./signin/SsoCallback.vue";
import Signin from "./signin/Signin.vue";
import RoutePopup from "./main_page/result/RoutePopup.vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { initApi } from "./api";
import Tracker from "@openreplay/tracker";
import { initAnalytics } from "./analytics";
import RegistrationCallback from "./signin/RegistrationCallback.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import Help from "./components/Help.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useFavicon, useTitle } from "@vueuse/core";
import FirstVisit from "./components/FirstVisit.vue";
import { useGlobalStore } from "./store";
import { sift } from "radash";
import { initI18n } from "./locale";
import Forum from "./forum/Forum.vue";
import ShareStory from "./forum/share_story/ShareStory.vue";
import ShareStorySuccess from "./forum/share_story/ShareStorySuccess.vue";
import Story from "./forum/story/Story.vue";
import ForumSignin from "./forum/ForumSignin.vue";

gtag("js", new Date());
gtag("config", "G-2Z7K3DQLXH", {
  debug_mode: config.analytics?.debug
});

if (config.openreplay) new Tracker(config.openreplay).start();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "/index:lang?",
      component: MainPage,
      name: "index",
      children: [
        {
          path: "login",
          component: Signin
        },
        {
          path: "suggestLogin",
          component: Signin,
          props: { suggestive: true }
        },
        {
          path: "route/:id",
          component: RoutePopup,
          props: true
        },
        {
          path: "help",
          component: Help
        },
        {
          path: "faq",
          component: Help,
          props: {
            faq: true
          }
        }
      ]
    },
    { path: "/firstVisit", component: FirstVisit },
    { path: "/signin", component: SsoCallback },
    {
      path: "/signin/confirm",
      component: RegistrationCallback,
      meta: {
        doNotRenderHeader: true
      }
    },
    {
      path: "/stories",
      component: Forum,
      children: [
        {
          path: "shareStory",
          component: ShareStory
        },
        {
          path: "shareStory/success",
          component: ShareStorySuccess
        },
        {
          path: "signin/:kind",
          component: ForumSignin,
          props: true
        }
      ]
    },
    {
      path: "/story/:id",
      component: Story,
      props: true
    },
    { path: "/:catchAll(.*)", redirect: "/index" }
  ]
});

router.beforeEach(to => {
  if (to.name == "index" && sift(useGlobalStore().documents).length == 0)
    return { path: "/firstVisit" };
});

const app = createApp(App);
app.component("icon", FontAwesomeIcon);
app.use(pinia);
app.use(router);

app.component("icon", FontAwesomeIcon);
app.component("VueDatePicker", VueDatePicker);

initI18n();
initApi({ app });
initAnalytics({ router });

app.mount("#app");

useFavicon(() => (config.staging ? "favicon-neg.ico" : "favicon.ico"));
useTitle(() => (config.staging ? "Staging Passportal" : "Passportal"));
