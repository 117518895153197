import { defineStore } from "pinia";
import { ref } from "vue";
import { LocationSuggestion } from "./locations/common";
import {
  DocumentDto,
  FindRoutesRequest,
  Route,
  Segment,
  SegmentGroup
} from "../api/client";
import { defineEmitter } from "../common";
import { Filters, SortOrder } from "./sort_filter";

export type RouteExt = Omit<Route, "segments"> & {
  id: string;
  segments: SegmentGroupExt[];
};

export type SegmentGroupExt = Omit<SegmentGroup, "segments"> & {
  segments: SegmentExt[];
};

export type SegmentExt = Segment & {
  id: string;
};

export const useMainPageStore = defineStore(
  "main_page",
  () => {
    const search = {
      origin: ref<LocationSuggestion>(),
      destination: ref<LocationSuggestion>(),
      date: ref<Date>(),
      suggestedBy: ref<string[]>()
    };

    const lastSearch = ref<{
      origin: LocationSuggestion;
      destination: LocationSuggestion;
      date: Date;
      documents: DocumentDto[];
      req: FindRoutesRequest;
      routes: RouteExt[];
      first: boolean;
      reqId: string;
      error: boolean;
    }>();

    const visaWarning = ref<{
      lastUniqCountryWarning: Partial<Record<string, Date>>;
    }>({
      lastUniqCountryWarning: {}
    });

    const sortOrder = ref<SortOrder>("cheapest");
    const filters: Filters = {
      hideFatals: false,
      stops: undefined
    };

    const signInAlert = ref<{
      searchCount: number;
      lastShown: Date;
    }>({
      searchCount: 0,
      lastShown: new Date()
    });

    return {
      search,
      lastSearch,
      searchInProgress: ref(false),

      firstSearch: ref(true),
      showDocuments: ref(false),
      visaWarning,
      signInAlert,

      sortOrder,
      filters
    };
  },
  {
    persist: {
      paths: ["firstSearch", "visaWarning", "showDocuments", "signInAlert"]
    }
  }
);

export const useMainPageEmitter = defineEmitter<{
  scrollToDocuments: void;
  scrollToLocations: void;
  highlightLocations: "from" | "to";
  highlightAddVisa: void;
  highlightLastDocument: void;
}>();
