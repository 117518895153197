<template>
  <div class="flex flex-col text-lg max-w-full">
    <router-link :to="`/index/route/${route.id}`" style="color: inherit">
      <div
        class="flex flex-row px-2 py-1 gap-x-2 md:gap-x-4 cursor-pointer select-none items-center">
        <div class="flex flex-col w-full">
          <div class="flex flex-row gap-x-2 md:gap-x-4 items-center">
            <icon
              :icon="iconBySeverity(status)"
              :class="colorBySeverity.text[status]" />
            <CarrierLogo
              class="w-7 h-7 md:w-auto md:h-auto"
              :code="carriers[0]?.code" />
            <div v-if="transitCountries.length">
              <div class="text-sm">{{ i18n.t("Transit through") }}</div>
              <div>{{ transitCountries.join(", ") }}</div>
            </div>
            <div v-else>{{ i18n.t("Direct Flight") }}</div>
            <div v-if="isMd">
              <div class="text-sm">{{ i18n.t("Duration") }}</div>
              <div>{{ formatTime(props.route.duration) }}</div>
            </div>
            <div v-if="isMd">
              <div class="text-sm">{{ i18n.t("Layover") }}</div>
              <div>{{ formatTime(layover) }}</div>
            </div>
            <div class="grow"></div>
            <div>
              <Price :amount="route.price" />
            </div>
          </div>
          <div
            v-if="!isMd"
            class="flex flex-row gap-x-2 md:gap-x-4 text-base font-bold text-gray-600">
            <div>
              {{ i18n.t("Travel time:") }}
              <div>{{ formatTime(props.route.duration) }}</div>
            </div>
            <div>
              {{ i18n.t("Layover time:") }}
              <div>{{ formatTime(layover) }}</div>
            </div>
          </div>
        </div>
        <button class="ml-auto text-base">{{ i18n.t("Select") }}</button>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { sift, unique } from "radash";
import { computed } from "vue";
import { CheckResult } from "../../api/client";
import CarrierLogo from "../../components/CarrierLogo.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { RouteExt } from "../store";
import Price from "../../components/currency/Price.vue";
import { useI18n } from "../../locale";
import { colorBySeverity } from "../../common";

const props = defineProps<{
  route: RouteExt;
}>();

const i18n = useI18n();

const isMd = useBreakpoints(breakpointsTailwind).greaterOrEqual("md");

const transitCountries = computed(() => {
  const countries = props.route.segments
    .flatMap(sg => sg.segments)
    .flatMap(s => [s.from, s.to])
    .slice(1, -1);

  return unique(countries, loc => loc.countryCode).map(loc => loc.countryName);
});
const layover = computed(
  () =>
    props.route.segments
      .filter(s => s.kind != "manual")
      .flatMap(s => s.segments)
      .reduce<[number, string | null]>(
        ([acc, arr], v) => {
          return [
            arr
              ? acc + new Date(v.departure!).getTime() - new Date(arr).getTime()
              : acc,
            v.arrival!
          ];
        },
        [0, null]
      )[0] / 1000
);
const status = computed((): CheckResult["severity"] => {
  const status = unique(
    sift(
      props.route.segments.flatMap(s => s.segments).map(s => s.check?.severity)
    )
  );
  if (status.includes("fatal")) return "fatal";
  if (status.includes("warning")) return "warning";
  return "info";
});
const carriers = computed(() => {
  return unique(
    sift(props.route.segments.flatMap(s => s.segments.map(s => s.carrier))),
    c => c.code
  );
});

function formatTime(duration: number) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.round((duration / 60) % 60);
  return `${hours ? hours + "h " : ""}${minutes}m`;
}

function iconBySeverity(severity: CheckResult["severity"] | undefined): string {
  if (severity == "fatal") return "fa-circle-xmark";
  if (severity == "warning") return "fa-triangle-exclamation";
  return "fa-circle-check";
}
</script>

<style scoped>
.blured {
  background: linear-gradient(transparent, transparent 5%, white 100%);
}
</style>
