<template>
  <div class="relative">
    <div ref="container" class="h-full w-full rounded-2xl"></div>
    <div class="absolute left-4 bottom-4 bg-white rounded py-1 px-2 z-[1000]">
      <span>{{ i18n.t("Show tooltips:") }} </span>
      <input v-model="showTooltips" class="ml-2" type="checkbox" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckResult } from "../../../api/client";
import * as L from "leaflet";
import { Component, createApp, ref, shallowRef } from "vue";
import "leaflet/dist/leaflet.css";
import { whenever } from "@vueuse/core";
import Marker from "./Marker.vue";
import { RouteExt } from "../../store";
import { useI18n } from "../../../locale";

const props = defineProps<{
  route: RouteExt;
}>();

const i18n = useI18n();

const container = shallowRef<HTMLElement>();
const showTooltips = ref(true);

function colorBySeverity(severity: CheckResult["severity"]) {
  if (severity == "fatal") return "text-red-600";
  if (severity == "warning") return "text-amber-400";
  return "text-green-600";
}

whenever(container, container => {
  const stops = [
    props.route.segments[0].segments[0].from,
    ...props.route.segments.flatMap(sg => sg.segments).map(s => s.to)
  ];

  const map = L.map(container, {
    minZoom: 2,
    maxZoom: 7,
    maxBounds: new L.LatLngBounds([-60, -180], [75, 180]),
    maxBoundsViscosity: 1.0
  }).setView([0, 0], 5);

  L.tileLayer("https://static.passportal.org/maptiles/{z}/{x}/{y}.png", {
    tileSize: 512,
    zoomOffset: -1
  }).addTo(map);

  const line = L.polyline(stops.map(l => l.coordinates)).addTo(map);
  map.fitBounds(line.getBounds());
  line.remove();

  for (const seg of props.route.segments.flatMap(sg => sg.segments)) {
    L.polyline([seg.from.coordinates, seg.to.coordinates], {
      color: "currentColor",
      className: colorBySeverity(seg.check?.severity ?? "info"),
      weight: 4
    }).addTo(map);
  }

  function vueIcon<Props>(component: Component<Props>, props?: Props): L.Icon {
    const cls = L.Icon.extend({
      createIcon() {
        const el = document.createElement("div");
        el.classList.add("leaflet-marker-icon");
        createApp(component, props as any).mount(el);
        return el;
      }
    });
    return new cls();
  }

  for (const [idx, l] of stops.entries()) {
    L.marker(l.coordinates, {
      icon: vueIcon(Marker, {
        pos: idx == 0 ? "first" : idx == stops.length - 1 ? "last" : undefined,
        loc: l,
        showTooltips
      })
    }).addTo(map);
  }
});
</script>

<style scoped></style>
