<template>
  <div>
    <div class="hidden md:flex items-center gap-x-4 md:gap-x-8">
      <router-link class="header-link relative font-bold" to="/stories">
        Stories
        <img class="w-10 absolute -top-5 -right-6" src="/new-icon.svg" />
      </router-link>
      <router-link class="header-link" to="/index/help">HELP</router-link>
      <router-link class="header-link" to="/index/faq">FAQ</router-link>
      <LangSelector />
      <CurrencySelect />
      <AccountWidget />
    </div>
    <div class="md:hidden relative">
      <icon class="h-8 text-gray-500" icon="fa-bars" @click="show = true" />
      <div
        v-if="show"
        ref="popup"
        class="absolute top-0 right-0 p-6 px-8 pt-4 container-widget bg-white flex flex-col items-center gap-y-3">
        <router-link class="header-link relative font-bold" to="/stories">
          Stories
          <img class="w-10 absolute -top-5 -right-6" src="/new-icon.svg" />
        </router-link>
        <router-link class="header-link" to="/index/help" @click="show = false">
          HELP
        </router-link>
        <router-link class="header-link" to="/index/faq" @click="show = false">
          FAQ
        </router-link>
        <LangSelector class="z-1" />
        <CurrencySelect class="[&_input]:text-center" />
        <AccountWidget @click="show = false" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AccountWidget from "./signin/AccountWidget.vue";
import LangSelector from "./components/lang_selector/LangSelector.vue";
import CurrencySelect from "./components/currency/CurrencySelect.vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const show = ref(false);
const popup = ref<HTMLElement>();

onClickOutside(popup, () => (show.value = false));
</script>

<style lang="sass" scoped>
.header-link
  @apply text-lg cursor-pointer text-gray-600 hover:text-inherit
</style>
