<template>
  <div class="flex flex-col p-4">
    <div class="text-xl mb-4"></div>
    <div
      class="mt-10 lg:mt-3 flex flex-col lg:flex-row lg:items-end gap-y-4 lg:gap-x-2 leading-4">
      <div class="grow relative">
        <label class="absolute -top-7">{{
          i18n.t("From (City or Airport)")
        }}</label>
        <LocationInput
          ref="fromEl"
          class="rounded"
          v-model="mainPageStore.search.origin"
          :placeholder="i18n.t('From (City or Airport)')"
          @submit="emit('submit')" />
      </div>
      <div
        class="button flex justify-center items-center self-center p-2 lg:p-2 w-full lg:w-auto border border-gray-500 rounded-md text-gray-600 cursor-pointer text-center"
        @click="swap">
        <icon
          :icon="lg ? 'fa-arrow-right-arrow-left' : 'fa-retweet'"
          class="fa-lg" />
      </div>
      <div class="grow relative mt-6 lg:mt-0">
        <label class="absolute -top-7">{{
          i18n.t("To (City or Airport)")
        }}</label>
        <LocationInput
          ref="toEl"
          class="rounded"
          v-model="mainPageStore.search.destination"
          :placeholder="i18n.t('To (City or Airport)')"
          @submit="emit('submit')" />
      </div>
      <div class="relative mt-6 lg:mt-0 lg:ml-2">
        <label class="absolute -top-7">{{ i18n.t("Date") }}</label>
        <VueDatePicker
          v-model="mainPageStore.search.date"
          :clearable="false"
          required
          :enable-time-picker="false"
          :min-date="new Date()"
          auto-apply
          openreplay-reveal />
      </div>
      <button
        v-if="!props.noButton"
        class="lg:self-stretch"
        :disabled="props.disabled"
        @click="emit('submit')">
        {{ i18n.t("Search") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useAnimateGreenFlash, useEmitterEvent } from "../../common";
import { useMainPageEmitter, useMainPageStore } from "../store";
import LocationInput from "./LocationInput.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useI18n } from "../../locale";

const props = defineProps<{
  disabled?: boolean;
  noButton?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit"): void;
}>();

const mainPageStore = useMainPageStore();
const mainPageEmitter = useMainPageEmitter();
const lg = useBreakpoints(breakpointsTailwind).greaterOrEqual("lg");
const i18n = useI18n();

const fromEl = ref();
const toEl = ref();
const greenFlashFrom = useAnimateGreenFlash(fromEl);
const greenFlashTo = useAnimateGreenFlash(toEl);

useEmitterEvent(mainPageEmitter, "highlightLocations", loc => {
  if (loc == "from") greenFlashFrom();
  else greenFlashTo();
});

function swap() {
  const temp = mainPageStore.search.origin;
  mainPageStore.search.origin = mainPageStore.search.destination;
  mainPageStore.search.destination = temp;
}
</script>

<style scoped></style>
