<template>
  <div class="container-widget p-7 bg-white">
    <form v-if="!submited" class="flex flex-col gap-2" @submit.prevent="submit">
      <div class="text-lg font-bold">
        {{ i18n.t("Why do you want to use crypto?") }}
      </div>
      <div
        v-if="showPurposeErr"
        class="text-sm text-red-500"
        ref="purposeErrEl">
        {{ i18n.t("Please select at least one") }}
      </div>
      <label>
        <input
          type="checkbox"
          name="purpose"
          v-model="purposes"
          value="anonymity"
          @change="showPurposeErr = false" />
        {{ i18n.t("Anonymity / privacy") }}
      </label>
      <label>
        <input
          type="checkbox"
          name="purpose"
          v-model="purposes"
          value="convenience"
          @change="showPurposeErr = false" />
        {{ i18n.t("Convenience") }}
      </label>
      <label>
        <input
          type="checkbox"
          name="purpose"
          v-model="purposes"
          value="support"
          @change="showPurposeErr = false" />
        {{ i18n.t("Support crypto future") }}
      </label>
      <label>
        <input
          type="checkbox"
          name="purpose"
          v-model="purposes"
          value="other"
          @change="showPurposeErr = false" />
        {{ i18n.t("Other") }}
      </label>

      <div class="mt-5 text-lg font-bold">
        {{ i18n.t("What crypto would you like to use?") }}
      </div>
      <div v-if="showCoinErr" class="text-sm text-red-500" ref="coinErrEl">
        {{ i18n.t("Please select at least one") }}
      </div>
      <label v-for="c in ['BTC', 'ETH', 'XMR', 'USDT']">
        <input
          type="checkbox"
          name="coin"
          v-model="coins"
          :value="c"
          @change="showCoinErr = false" />
        {{ c }}
      </label>
      <label>
        <input
          type="checkbox"
          name="coin"
          v-model="coins"
          value="other"
          @change="showCoinErr = false" />
        {{ i18n.t("Other") }}
      </label>
      <button class="mt-5">{{ i18n.t("Submit") }}</button>
    </form>
    <div v-else>
      <p class="font-bold text-lg">{{ i18n.t("Thanks for your interest.") }}</p>
      <p class="mt-1">
        {{
          i18n.t(
            "We are working on implementing crypto payments. Your feedback is very important."
          )
        }}
      </p>
      <p class="mt-2 mb-3">
        <router-link to="/index/login">{{ i18n.t("Sign up") }}</router-link>
        {{ i18n.t("and follow us on") }}
        <a href="https://twitter.com/llcpassportal" target="_blank">{{
          i18n.t("Twitter")
        }}</a>
        {{
          i18n.t(
            "if you want to receive an update when the feature will be added."
          )
        }}
      </p>
    </div>
    <button
      class="mt-2 w-full"
      :class="{ 'bg-gray-300': !submited }"
      @click="$emit('close')">
      {{ i18n.t("Close") }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { postEvent } from "../../analytics";
import { nextTick, ref } from "vue";
import { useI18n } from "../../locale";

const i18n = useI18n();

const purposes = ref<string[]>([]);
const coins = ref<string[]>([]);
const showPurposeErr = ref(false);
const showCoinErr = ref(false);
const purposeErrEl = ref<HTMLElement>();
const coinErrEl = ref<HTMLElement>();

const submited = ref(false);

function submit() {
  if (!purposes.value.length) {
    showPurposeErr.value = true;
    nextTick().then(() => {
      purposeErrEl.value?.scrollIntoView({ behavior: "smooth" });
    });
    return;
  }
  if (!coins.value.length) {
    showCoinErr.value = true;
    nextTick().then(() => {
      coinErrEl.value?.scrollIntoView({ behavior: "smooth" });
    });
    return;
  }
  submited.value = true;
  postEvent("crypto_survey.submit", {
    answers: {
      purposes: purposes.value,
      coins: coins.value
    }
  });
}

postEvent("crypto_survey.open");
</script>

<style lang="sass" scoped>
label, label > input
  @apply cursor-pointer

label
  @apply flex items-center gap-3
</style>
