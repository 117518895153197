import { computedAsync } from "@vueuse/core";
import { computed } from "vue";
import { useGeoIP } from "../../common";

export { default as currencies } from "./currencies.json";

export const currencyCountries = computedAsync<
  Record<string, string | undefined>
>(async () => {
  const resp = await fetch(
    "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/country.json"
  );
  return Object.fromEntries(
    Object.entries(await resp.json())
      .map(([countryCode, { currency_code }]: [string, any]) => [
        countryCode.toUpperCase(),
        currency_code.toUpperCase()
      ])
      .filter(([_, code]) => code.length == 3)
  );
}, {});

export const exchangeRates = computedAsync<Record<string, number | undefined>>(
  async () => {
    const resp = await fetch(
      "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur.min.json"
    );
    return Object.fromEntries(
      Object.entries((await resp.json()).eur).map(([k, v]) => [
        k.toUpperCase(),
        v as number
      ])
    );
  },
  {}
);

export const defaultCurrency = computed(() => {
  return currencyCountries.value[useGeoIP.value?.countryCode ?? ""] ?? "USD";
});
