import {
  Client,
  fetchExchange,
  install as installUrql,
  mapExchange,
  OperationResult
} from "@urql/vue";
import { App } from "vue";
import { buildSchema } from "graphql/utilities";
import graphqlSchema from "./gql/schema.graphql?raw";
import { GraphQLScalarType } from "graphql/type";
import { execute } from "graphql/execution";

const addOperationName = mapExchange({
  onOperation: op => {
    for (const def of op.query.definitions) {
      if (def.kind == "OperationDefinition" && def.name) {
        op.context.url += "/" + def.name.value;
      }
    }
  }
});

function scalars(scalars: Record<string, (v: any) => any>) {
  return mapExchange({
    async onResult(result: OperationResult): Promise<OperationResult> {
      if (!result.data) return result;

      let schema = buildSchema(graphqlSchema);

      for (const [name, deserializer] of Object.entries(scalars)) {
        const scalar = schema.getType(name);
        if (scalar instanceof GraphQLScalarType) {
          scalar.serialize = deserializer;
        } else {
          throw new Error(`${name} is not a scalar: ${scalar}`);
        }
      }

      const res = await execute({
        schema: schema,
        document: result.operation.query,
        rootValue: result.data,
        variableValues: result.operation.variables as any
      });

      result.data = res.data;

      return result;
    }
  });
}

export function initGraphql({
  app,
  customFetch
}: {
  app: App;
  customFetch: typeof fetch;
}) {
  const graphqlClient = new Client({
    url: config.apiUrl + "/graphql",
    exchanges: [
      scalars({
        DateTime: v => new Date(v)
      }),
      addOperationName,
      fetchExchange
    ],
    fetch: customFetch
  });
  installUrql(app, graphqlClient);
}
