<template>
  <div class="bg-white p-10 container-widget w-full md:w-auto" @click.stop>
    <div class="flex md:flex-row h-20 justify-center gap-x-1 items-center">
      <img
        class="h-full grow object-contain"
        :src="getPassportImgUrl(passport)" />
      <icon icon="fa-minus" class="fa-2xl" />
      <img class="h-[80%]" src="./visa.svg" />
      <icon icon="fa-arrow-right" class="fa-2xl" />
      <Flag class="h-full grow" :country-code="destination.countryCode" />
    </div>
    <div class="flex flex-col text-center pt-10">
      <div class="text-lg font-bold">
        {{ i18n.t("Your passport requires additional documentation") }}
      </div>
      <div class="text-lg">
        {{ i18n.t("in order to travel to") }} {{ destination.countryName }}.
      </div>
    </div>
    <div class="flex flex-col pt-10 gap-y-5">
      <button @click="emit('addVisa', destination)">
        {{ i18n.t("I have a Visa") }}
      </button>
      <button
        v-element-visibility="
          () => {
            postEvent('visa_survey.proposed');
          }
        "
        class="fancy-gradient text-white font-bold"
        @click="visaSurvey = true">
        {{ i18n.t("I want a Visa") }}
      </button>
      <button
        @click="emit('anotherPassport', destination)"
        class="bg-orange-300">
        {{ i18n.t("I have a different passport") }}
      </button>
      <button @click="emit('skip', destination)" class="bg-gray-300">
        {{ i18n.t("Skip") }}
      </button>
    </div>
    <Overlay v-model="visaSurvey" class="z-[1001]">
      <VisaSurvey :destination="destination" @close="visaSurvey = false" />
    </Overlay>
  </div>
</template>

<script setup lang="ts">
import Flag from "../components/Flag.vue";
import Overlay from "../components/overlay/Overlay.vue";
import VisaSurvey from "../main_page/result/VisaSurvey.vue";
import { getPassportImgUrl } from "./documents/common";
import { Location } from "../api/client";
import { postEvent } from "../analytics";
import { ref } from "vue";
import { vElementVisibility } from "@vueuse/components";
import { useI18n } from "../locale";

defineProps<{
  passport: string;
  destination: Location;
}>();

const emit = defineEmits<{
  (e: "addVisa", value: Location): void;
  (e: "anotherPassport", value: Location): void;
  (e: "skip", value: Location): void;
}>();

const i18n = useI18n();

const visaSurvey = ref(false);
</script>
