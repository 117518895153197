import { computed, customRef, reactive, watchEffect } from "vue";
import { setupI18n } from "@lingui/core";
import { memo } from "radash";
import { useGlobalStore } from "./store";
import langs from "../locales/langs.json";

const i18n = setupI18n();

export const DEFAULT_LOCALE = "en";
export const SUPPORTED_LOCALES = langs.map(l => l.code);

export function initI18n() {
  i18n.load(DEFAULT_LOCALE, {});

  watchEffect(async () => {
    const locale = useGlobalStore().locale;
    if (locale == DEFAULT_LOCALE) {
      i18n.activate(DEFAULT_LOCALE);
    } else {
      const messages = (await import(`../locales/${locale}.po`)) as any;
      i18n.loadAndActivate({
        locale,
        messages: messages.messages
      });
    }
  });
}

export const useI18n = memo(() => {
  const ref = customRef((track, trigger) => {
    i18n.on("change", trigger);

    return {
      get() {
        track();
        return i18n;
      },
      set() {}
    };
  });

  return reactive({
    t: computed(() => ref.value.t),
    locale: computed(() => ref.value.locale)
  });
});

export function guessDefaultLocale() {
  for (const locale of navigator.languages) {
    const guessed = SUPPORTED_LOCALES.find(l => locale.startsWith(l));
    if (guessed) return guessed;
  }
  return DEFAULT_LOCALE;
}
