<template>
  <div
    class="container-widget bg-white w-full md:w-1/4 p-10 flex items-center gap-x-5">
    <icon class="text-3xl text-red-600" icon="fa-circle-xmark" />
    <div v-if="statusCode == 429" class="text-xl">
      {{ i18n.t("Please wait a little bit before next request") }}
    </div>
    <div v-else>
      <div class="font-bold">{{ i18n.t("We encountered an error") }}</div>
      <div>
        {{ i18n.t("Please try again and contact us in") }}
        <a href="https://t.me/passportalofficial" target="_blank">{{
          i18n.t("Telegram")
        }}</a>
      </div>
      <div class="text-sm">{{ i18n.t("Error code") }}: ({{ statusCode }})</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "../locale";

defineProps<{
  statusCode: number;
}>();

const i18n = useI18n();
</script>

<style scoped></style>
