<template>
  <div>
    <div
      ref="containerEl"
      :style="{ paddingBottom: `${aspectRatio * 100}%` }"
      class="w-full relative overflow-hidden rounded-xl bg-white">
      <svg
        ref="svgEl"
        :style="svgStyle"
        class="absolute top-0 left-0"
        viewBox="0 0 1000 555"
        xmlns="http://www.w3.org/2000/svg">
        <g
          v-for="country of countriesWithResult"
          @click="dragged ? null : (showDetails = country)">
          <path
            :class="
              (country.result &&
                colorBySeverity.text[country.result.check.severity]) ??
              'text-gray-400'
            "
            :d="country.path"
            fill="currentColor"
            @mouseenter="showTooltip = country"
            @mouseleave="showTooltip = undefined"></path>
        </g>
      </svg>

      <div class="absolute right-2 bottom-2 flex flex-col py-1">
        <div
          class="zoom-btn rounded-t-xl"
          @click="setScale(scale + 1, 'button')">
          <icon :icon="faPlus" />
        </div>
        <div
          class="zoom-btn rounded-b-xl"
          @click="setScale(scale - 1, 'button')">
          <icon :icon="faMinus" />
        </div>
      </div>

      <div
        v-if="fetching"
        class="absolute left-4 top-4 w-14 h-14"
        v-html="loaderSvg"></div>

      <div
        v-if="showDetails"
        class="absolute w-full h-full flex items-center justify-center bg-black/10">
        <div
          v-on-click-outside="() => (showDetails = undefined)"
          class="w-1/2 h-[90%] overflow-y-auto container-widget bg-white p-2">
          <div class="flex items-center border-b-2 border-gray-200 pb-2">
            <div class="text-lg font-bold" v-text="showDetails.name"></div>
            <Flag :country-code="showDetails.code" class="h-6 ml-2" />
            <div
              v-if="showDetails.result"
              :class="colorBySeverity.bgAll[showDetails.result.check.severity]"
              class="w-5 h-5 ml-2 rounded-full"></div>
            <div class="grow"></div>
            <button
              v-if="showDetails.capitalId"
              @click="buildRoute(showDetails.capitalId)">
              {{ i18n.t("Build route") }}
            </button>
            <icon
              :icon="faXmark"
              class="self-center ml-2 text-2xl cursor-pointer"
              @click="showDetails = undefined" />
          </div>
          <div v-if="showDetails.result" class="flex flex-col gap-y-1 mt-2">
            <div
              v-for="msg of showDetails.result.check.messages"
              class="rounded overflow-hidden">
              <div
                :class="colorBySeverity.bgLight[msg.severity]"
                v-text="msg.text"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="absolute left-7 bottom-3 text-xs text-gray-400 pointer-events-none">
        {{ i18n.t("Availability for your documents") }}<br />
        {{ i18n.t("From") }} {{ origin?.name }}
      </div>

      <a
        class="absolute left-2 bottom-5"
        href="https://github.com/10bestdesign/jqvmap/blob/master/LICENSE"
        target="_blank">
        <icon :icon="faInfoCircle" class="text-gray-400 text-xs" />
      </a>
    </div>

    <div
      v-if="showTooltip"
      :style="{ left: `${mouseX}px`, top: `${mouseY}px` }"
      class="fixed -translate-y-full mx-2 bg-white py-1 px-2 rounded text-sm pointer-events-none">
      {{ showTooltip.name }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
.zoom-btn
  @apply w-8 h-8 flex justify-center items-center bg-blue-400 hover:bg-blue-300 text-xl
</style>

<script lang="ts" setup>
import {
  useElementBounding,
  useEventListener,
  useMouse,
  useMouseInElement,
  watchOnce
} from "@vueuse/core";
import { objectify, pick, sift } from "radash";
import { computed, nextTick, reactive, ref, watch } from "vue";
import countries from "./countries.json";
import capitals from "@data/capitals.json";
import { FontAwesomeIcon as Icon } from "@fortawesome/vue-fontawesome";
import {
  faInfoCircle,
  faMinus,
  faPlus,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import {
  CountryCheckResult,
  routerGetLocation,
  visaCheckCheckWorld
} from "../api/client";
import { useGlobalStore } from "../store";
import { useMainPageEmitter, useMainPageStore } from "../main_page/store";
import { handle200 } from "../api";
import loaderSvg from "../components/logo-animated.svg?raw";
import { colorBySeverity } from "../common";
import { vOnClickOutside } from "@vueuse/components";
import Flag from "../components/Flag.vue";
import { ok } from "oazapfts";
import { makeSuggestion } from "../main_page/locations/common";
import { documentIssuers } from "../main_page/documents/common";
import { useI18n } from "../locale";
import { postEvent } from "../analytics";

export interface Country {
  name: string;
  code: string;
  path: string;
  capitalId: string | null;
  result?: CountryCheckResult;
}

const i18n = useI18n();

const store = useGlobalStore();
const mainPageStore = useMainPageStore();
const mainPageEmitter = useMainPageEmitter();

const checkResult = ref<CountryCheckResult[]>([]);
const fetching = ref(false);

const origin = computed(() => {
  let issuer = sift(store.documents).find(
    d => d.type.canonicalName == "Passport"
  )?.issuer;
  if (!issuer?.countryCode)
    issuer = documentIssuers.value.find(i => i.countryCode == "TR")!;
  if (issuer.countryCode == "UA")
    issuer = documentIssuers.value.find(i => i.countryCode == "MD")!;
  return issuer;
});
watch(
  () => ({
    documents: sift(store.documents).map(d => ({
      type: d.type.id,
      issuer: d.issuer.id
    })),
    originCountry: origin.value?.countryCode!
  }),
  async (req, oldReq) => {
    if (JSON.stringify(req) == JSON.stringify(oldReq)) return;
    if (!req.originCountry) return;

    fetching.value = true;
    try {
      const resp = await handle200(visaCheckCheckWorld(req));
      checkResult.value = resp.data.results;
    } catch (e) {
      checkResult.value = [];
      console.error(e);
      postEvent("travelmap.error", { msg: String(e) });
    } finally {
      fetching.value = false;
    }
  },
  { immediate: true }
);

const countriesWithResult = computed(() => {
  const index = objectify(checkResult.value, r => r.countryCode);
  return countries.map(
    c =>
      ({
        ...c,
        capitalId: capitals[c.code as keyof typeof capitals].capitalId,
        result: (index as Partial<typeof index>)[c.code]
      }) satisfies Country
  );
});

async function buildRoute(capitalId: string) {
  postEvent("travelmap.buildRoute");

  mainPageStore.search.destination = makeSuggestion(
    await ok(routerGetLocation(capitalId))
  );
  mainPageEmitter.emit("scrollToLocations");
  mainPageEmitter.emit("highlightLocations", "to");
}

const showTooltip = ref<Country>();
const showDetails = ref<Country>();

watch(showDetails, dt => {
  if (dt) postEvent("travelmap.showDetails");
});
watchOnce(showTooltip, () => postEvent("travelmap.showTooltip"));

const svgEl = ref<HTMLElement>();
const containerEl = ref<HTMLElement>();

const mouse = reactive(useMouseInElement(containerEl));
const svgBounding = reactive(useElementBounding(svgEl));
const containerBounding = reactive(useElementBounding(containerEl));

const { x: mouseX, y: mouseY } = useMouse();

const scale = ref(0);

const posRef = reactive({ x: 0, y: 0 });
const pos = reactive({
  x: computed({
    get: () => posRef.x,
    set: v => {
      v = Math.min(v, 0);
      v = Math.max(v, containerBounding.width - svgBounding.width);

      posRef.x = v;
    }
  }),
  y: computed({
    get: () => posRef.y,
    set: v => {
      v = Math.min(v, 0);
      v = Math.max(v, containerBounding.height - svgBounding.height);

      posRef.y = v;
    }
  })
});

async function setScale(value: number, source: "button" | "wheel") {
  if (value < 0 || value > 2) return;

  const oldSize = pick(svgBounding, ["width", "height"]);

  let centerPoint: { x: number; y: number };
  if (source == "button")
    centerPoint = {
      x: containerBounding.width / 2,
      y: containerBounding.height / 2
    };
  else
    centerPoint = {
      x: mouse.elementX,
      y: mouse.elementY
    };

  scale.value = value;
  await nextTick();

  svgBounding.update();
  const newSize = pick(svgBounding, ["width", "height"]);

  const centerX = (centerPoint.x - pos.x) / oldSize.width;
  pos.x = centerPoint.x - newSize.width * centerX;
  const centerY = (centerPoint.y - pos.y) / oldSize.height;
  pos.y = centerPoint.y - newSize.height * centerY;
}

// Mouse wheel scaling
useEventListener(containerEl, "wheel", ev => {
  if (showDetails.value) return;
  ev.preventDefault();

  if (Math.abs(ev.deltaY) < 50) return;
  if (ev.deltaY > 0) setScale(scale.value - 1, "wheel");
  else setScale(scale.value + 1, "wheel");
});

// Dragging
const dragging = ref(false);
const dragged = ref(false);
const draggingPos = reactive({ x: 0, y: 0 });

useEventListener(svgEl, "pointerdown", ev => {
  if (showDetails.value) return;
  Object.assign(draggingPos, pos);
  dragging.value = true;
  dragged.value = false;
});
useEventListener(svgEl, "pointerup", () => {
  dragging.value = false;
});
useEventListener(svgEl, "pointermove", ev => {
  if (dragging.value) {
    // By capturing pointer we eliminate text selection outside the map,
    // also ensure that we receive pointerup even if cursor is dragged outside
    // We capture it here so that click event will still work to open details
    if (!dragged.value) svgEl.value?.setPointerCapture(ev.pointerId);
    dragged.value = true;

    pos.x = draggingPos.x += ev.movementX;
    pos.y = draggingPos.y += ev.movementY;
  }
});

const aspectRatio = 0.4848;

const baseStyle = computed(() => {
  const realScale = 2 ** scale.value;

  return {
    width: `${containerBounding.width * realScale}px`,
    height: `${containerBounding.width * aspectRatio * realScale}px`
  };
});
const svgStyle = computed(() => {
  return {
    ...baseStyle.value,
    transform: `translateX(${pos.x}px) translateY(${pos.y}px)`
  };
});
watch(svgStyle, () => svgBounding.update());
</script>
