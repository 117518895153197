<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div
        v-for="dest in destinations"
        class="button border rounded flex flex-row items-center gap-1 md:gap-2 p-1 truncate cursor-pointer"
        @click="emit('submit', makeSuggestion(dest))">
        <Flag class="h-5" :country-code="dest.countryCode" />
        <span>{{ dest.countryName }}</span>
        <span v-if="dest.cityName" class="text-gray-600">|</span>
        <span v-if="dest.cityName" class="text-gray-600">{{
          dest.cityName
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Flag from "../components/Flag.vue";
import { LocationSuggestion, makeSuggestion } from "./locations/common";
import {
  Location,
  popularDestinationsGetPopularDestinations
} from "../api/client";
import { ok } from "oazapfts";
import { computedAsync } from "@vueuse/core";
import defaultDestinations from "./popular_destinations.json";

const props = defineProps<{
  destinations?: Location[];
}>();
const emit = defineEmits<{
  (e: "submit", value: LocationSuggestion): void;
}>();

const destinations = computedAsync(async () => {
  if (props.destinations) return props.destinations;
  return await ok(popularDestinationsGetPopularDestinations());
}, defaultDestinations);
</script>
