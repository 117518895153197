import { Location, routerCompleteLocation } from "../../api/client";
import { Suggestion } from "../../components/Autocomplete.vue";
import { useI18n } from "../../locale";

export type LocationSuggestion = Location & Suggestion;

export async function fetchSuggestions(
  text: string
): Promise<LocationSuggestion[]> {
  const resp = await routerCompleteLocation(text);
  if (resp.status != 200) return [];

  return resp.data.locations.map(makeSuggestion);
}

export function makeSuggestion(location: Location): LocationSuggestion {
  const i18n = useI18n();

  let middle: string;
  if (location.type == "airport") {
    middle = `${location.name} (${location.code})`;
  } else {
    middle = i18n.t("all airports");
  }

  return {
    ...location,
    text: `${location.cityName}, ${location.countryName}, ${middle} (${location.countryCode})`
  };
}
