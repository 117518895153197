<template>
  <Autocomplete
    v-model="selected"
    :suggest-by-substring="false"
    :invalid="invalid"
    :invalid-message="i18n.t('Specify city name in selected language')"
    :placeholder="placeholder"
    :show-loader="showLoader"
    :suggestions="suggestions"
    class="w-full lg:min-w-72 lg:max-w-full"
    clear-on-focus
    show-flag
    suggest-lang-change
    @focusin="used = true"
    @show="showAutocomplete"
    @submit="emit('submit')"
    @update:text="text = $event" />
</template>

<script lang="ts" setup>
import { debounce } from "radash";
import { computed, ref, watch } from "vue";
import Autocomplete from "../../components/Autocomplete.vue";
import { fetchSuggestions, LocationSuggestion, makeSuggestion } from "./common";
import { useGlobalStore } from "../../store";
import { routerGetLocation } from "../../api/client";
import { handle200 } from "../../api";
import { useI18n } from "../../locale";

const emit = defineEmits<{
  (e: "update:modelValue", value: LocationSuggestion | undefined): void;
  (e: "submit"): void;
}>();

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    modelValue: LocationSuggestion | undefined;
  }>(),
  {
    modelValue: undefined
  }
);

const store = useGlobalStore();
const i18n = useI18n();

const selected = ref<LocationSuggestion>();
const text = ref("");
const suggestions = ref<LocationSuggestion[]>([]);
const showLoader = ref(false);
const autocompleteShown = ref(false);
const used = ref(false);

const invalid = computed(() =>
  Boolean(used.value && !autocompleteShown.value && !selected.value)
);

watch(selected, selected => emit("update:modelValue", selected));

watch(text, () => (used.value = true));
watch(
  text,
  debounce({ delay: 200 }, async (text: string) => {
    if (text == "" || text == selected.value?.text) return;

    showLoader.value = true;
    try {
      suggestions.value = await fetchSuggestions(text);
    } finally {
      showLoader.value = false;
    }
  })
);

watch(
  () => props.modelValue,
  suggested => {
    selected.value = suggested;
  },
  {
    immediate: true
  }
);

watch(
  () => store.locale,
  async () => {
    if (selected.value) {
      const res = await handle200(routerGetLocation(selected.value.id));
      selected.value = makeSuggestion(res.data);
    }
  }
);

async function showAutocomplete(show: boolean) {
  autocompleteShown.value = show;

  if (show || text.value == "" || selected.value) return;

  function autocomplete() {
    const match = suggestions.value.find(
      s => s.name.toLowerCase() == text.value.toLowerCase()
    );
    if (match) selected.value = match;
    return Boolean(match);
  }

  if (!autocomplete()) {
    await fetchSuggestions(text.value);
    autocomplete();
  }
}
</script>

<style scoped></style>
