<template>
  <div>
    <OnClickOutside @trigger="expand = false">
      <div
        class="h-10 border border-2 rounded flex flex-row items-center p-1 gap-x-1 cursor-pointer relative"
        @click="expand = !expand"
        @focusout="expand = false">
        <Flag
          v-if="langInfo.countryCode.toUpperCase() != 'RU'"
          :country-code="langInfo.countryCode"
          class="h-7" />
        <div>{{ langInfo.shortText ?? langInfo.countryCode }}</div>
        <icon :icon="expand ? 'fa-chevron-up' : 'fa-chevron-down'" />
        <div
          v-if="expand"
          class="absolute top-full right-0 bg-white rounded drop-shadow-xl flex flex-col w-max leading-10 overflow-y-scroll max-h-60">
          <div
            v-for="l in langs"
            class="hover:bg-gray-200 flex p-1 items-center gap-x-1"
            @click="lang = l.code">
            <Flag
              v-if="l.countryCode.toUpperCase() != 'RU'"
              :country-code="l.countryCode"
              class="h-7" />
            <div>{{ l.text }}</div>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import Flag from "../Flag.vue";
import { OnClickOutside } from "@vueuse/components";
import langs from "../../../locales/langs.json";
import { useGlobalStore } from "../../store";
import { postEvent } from "../../analytics";
import { guessDefaultLocale } from "../../locale";

const store = useGlobalStore();

const expand = ref(false);

const lang = computed({
  get() {
    return store.locale;
  },
  set(v) {
    postEvent("language.changed", {
      from: store.selectedLocale,
      guessed: guessDefaultLocale(),
      to: v
    });
    store.selectedLocale = v;
  }
});
const langInfo = computed(() => langs.find(l => l.code == lang.value)!);
</script>
