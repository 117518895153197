<template>
  <div ref="el" class="relative">
    <div v-if="globalStore.user">
      <button
        class="h-10 w-10 bg-gray-300 rounded-2xl p-3 text-center font-bold flex items-center justify-center"
        @click="popover = true">
        <div>{{ globalStore.user.email[0].toUpperCase() }}</div>
      </button>
    </div>
    <router-link
      v-else
      to="/index/login"
      class="button whitespace-nowrap"
      style="color: inherit; padding-top: 0.5rem; padding-bottom: 0.5rem">
      {{ i18n.t("Sign In") }}
    </router-link>
    <div v-if="popover" class="popover container-widget">
      <Signin v-if="!globalStore.user" show-title @skip="popover = false" />
      <div class="flex gap-4 items-center" v-else>
        <div v-text="globalStore.user.email" data-openreplay-hidden></div>
        <div
          class="h-10 w-10 bg-gray-300 rounded-2xl p-3 text-center font-bold flex items-center justify-center">
          <div>{{ globalStore.user.email[0].toUpperCase() }}</div>
        </div>
        <button class="py-2" @click="doSignOut()">
          {{ i18n.t("Sign out") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import Signin from "./Signin.vue";
import { onClickOutside } from "@vueuse/core";
import { useGlobalStore } from "../store";
import { useI18n } from "../locale";

const globalStore = useGlobalStore();
const i18n = useI18n();

const el = ref<HTMLElement>();
const popover = ref(false);

watch(
  () => globalStore.user,
  () => (popover.value = false)
);

onClickOutside(el, () => (popover.value = false));

async function doSignOut() {
  globalStore.setToken(null);
}
</script>

<style lang="sass" scoped>
.popover
  @apply fixed bg-white p-10 z-20 right-0 top-0 w-screen flex justify-center

@screen md
  .popover
    @apply absolute -right-1 -top-1 w-max
</style>
