import { Route } from "../api/client";
import { draw, sift, unique } from "radash";

const CheckSeverity = ["info", "warning", "fatal"] as const;
type CheckSeverity = (typeof CheckSeverity)[number];

const SortOrder = [
  "cheapest",
  "fastest",
  "cheapest_fastest",
  "priciest",
  "longest",
  "visa"
] as const;
export type SortOrder = (typeof SortOrder)[number];

export interface Filters {
  hideFatals: boolean;
  stops: number | undefined;
}

function routeStatus(r: Route) {
  const status = unique(
    sift(r.segments.flatMap(s => s.segments).map(s => s.check?.severity))
  );
  if (status.includes("fatal")) return "fatal";
  if (status.includes("warning")) return "warning";
  return "info";
}

function compareStatus(a: CheckSeverity, b: CheckSeverity): number {
  const iA = CheckSeverity.indexOf(a);
  const iB = CheckSeverity.indexOf(b);

  return iA > iB ? 1 : iA == iB ? 0 : -1;
}

export function sortRoutes<T extends Route>(
  routes: T[],
  sortOrder: SortOrder
): T[] {
  if (!routes.length) return routes;

  const avgDuration =
    routes.map(r => r.duration).reduce((a, b) => a + b) / routes.length;
  const avgPrice =
    routes.map(r => r.price).reduce((a, b) => a + b) / routes.length;

  function sortFn(): (a: Route, b: Route) => number {
    switch (sortOrder) {
      case "cheapest": {
        return (a, b) => (a.price < b.price ? -1 : 1);
      }
      case "fastest": {
        return (a, b) => (a.duration < b.duration ? -1 : 1);
      }
      case "cheapest_fastest": {
        return (a, b) => {
          const optA =
            -(avgPrice - a.price) / avgPrice +
            -(avgDuration - a.duration) / avgDuration;
          const optB =
            -(avgPrice - b.price) / avgPrice +
            -(avgDuration - b.duration) / avgDuration;
          return optA < optB ? -1 : 1;
        };
      }
      case "priciest": {
        return (a, b) => (a.price > b.price ? -1 : 1);
      }
      case "longest": {
        return (a, b) => (a.duration > b.duration ? -1 : 1);
      }
      case "visa": {
        return (a, b) => {
          const statusA = routeStatus(a);
          const statusB = routeStatus(b);
          const statusComparison = compareStatus(statusA, statusB);
          const priceComparison = a.price < b.price ? -1 : 1;
          return statusComparison == 0 ? priceComparison : statusComparison;
        };
      }
      default: {
        return (a, b) => (a.duration < b.duration ? -1 : 1);
      }
    }
  }

  return [...routes].sort(sortFn());
}

export function filterRoutes<T extends Route>(
  routes: T[],
  filters: Filters
): T[] {
  routes = routes.filter(route => {
    const statuses = unique(
      sift(route.segments.flatMap(s => s.segments).map(s => s.check?.severity))
    );
    if (statuses.includes("fatal") && filters.hideFatals) return false;

    if (filters.stops != undefined) {
      return (
        route.segments.flatMap(s => s.segments).length <= filters.stops + 1
      );
    }

    return true;
  });
  return routes;
}

export function pickExemplarRoutes<T extends Route>(routes: T[]) {
  let best = routes.slice(5, 10);
  if (!best.length) best = routes;

  const picked = sift([draw(best)!]);

  best = best.filter(r => routeStatus(r) != routeStatus(picked[0]));
  if (!best.length)
    best = routes.filter(r => routeStatus(r) != routeStatus(picked[0]));
  if (!best.length) best = routes;

  best = best.filter(r => r != picked[0]);
  if (best.length) picked.push(draw(best)!);

  best = best.filter(
    r =>
      routeStatus(r) != routeStatus(picked[0]) &&
      routeStatus(r) != routeStatus(picked[1])
  );
  if (!best.length)
    best = routes.filter(
      r =>
        routeStatus(r) != routeStatus(picked[0]) &&
        routeStatus(r) != routeStatus(picked[1])
    );
  if (!best.length) best = routes;

  best = best.filter(r => r != picked[0] && r != picked[1]);
  if (best.length) picked.push(draw(best)!);

  return picked;
}
