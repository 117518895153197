import cuid2 from "@paralleldrive/cuid2";
import * as Api from "./api/client";
import { analyticsKeepalive, analyticsPostEvent } from "./api/client";
import { handle200 } from "./api";
import { Router } from "vue-router";
import { MaybeRef, toValue, watchEffect } from "vue";
import { guessDefaultLocale } from "./locale";

const DEVICE_ID_KEY = "analytics:device_id";

export const DEVICE_ID_HEADER = "A-Device-Id";
export const SESSION_ID_HEADER = "A-Session-Id";

function loadDeviceId() {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (!deviceId) {
    const deviceId = cuid2.createId();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
    return deviceId;
  }
  return deviceId;
}

export const deviceId = loadDeviceId();
export const sessionId = cuid2.createId();

export function breakdownUrl(url: string) {
  const urlObj = url.startsWith("/")
    ? new URL(url, location.href)
    : new URL(url);
  return {
    url: urlObj.href,
    path: urlObj.pathname,
    host: urlObj.host,
    search: urlObj.searchParams
  };
}

export async function postEvent(name: string, data?: object): Promise<void> {
  if (config.analytics?.disable) return;
  await handle200(
    analyticsPostEvent({
      name: "front." + name,
      data
    })
  );
  gtag("event", name, data as any);
}

export function useVideoAnalytics(ref: MaybeRef<HTMLVideoElement | undefined>) {
  function setup(el: HTMLVideoElement) {
    function postVideoEvent(name: string) {
      postEvent("video." + name);
    }

    el.addEventListener("play", () => postVideoEvent("play"));
    el.addEventListener("pause", () => {
      if (!el.ended) postVideoEvent("pause");
    });
    el.addEventListener("ended", () => postVideoEvent("end"));
  }

  watchEffect(() => {
    const el = toValue(ref);
    if (el) setup(el);
  });
}

export function initAnalytics({ router }: { router: Router }) {
  Object.assign(Api.defaults.headers!, {
    [DEVICE_ID_HEADER]: deviceId,
    [SESSION_ID_HEADER]: sessionId
  });

  if (config.analytics?.disable) return;

  postEvent("page_enter", {
    url: breakdownUrl(location.href),
    referrer: document.referrer ? breakdownUrl(document.referrer) : undefined,
    ref: breakdownUrl(document.URL).search.get("r"),
    language: {
      preferred: navigator.languages,
      guessed: guessDefaultLocale()
    }
  });

  router.afterEach((to, from) => {
    postEvent("page_navigation", {
      from: from.path,
      to: to.path
    });
  });

  setInterval(() => {
    if (document.hasFocus()) analyticsKeepalive();
  }, 10000);
  window.addEventListener("beforeunload", () => {
    analyticsKeepalive();
  });
  // window.addEventListener("focus", () => {
  //   postEvent("page_focus");
  // });
  // window.addEventListener("blur", () => {
  //   postEvent("page_blur");
  // });

  document.body.addEventListener(
    "click",
    ev => {
      if (
        ev.target instanceof HTMLElement &&
        ev.target.tagName.toLowerCase() == "a"
      ) {
        const href = ev.target.getAttribute("href");
        if (href) {
          postEvent("link_click", {
            url: breakdownUrl(href)
          });
        }
      }
    },
    { capture: true }
  );
}
