<template>
  <Overlay :model-value="true" route>
    <Signin v-if="!store.user && !loginHandled">
      <div class="text-xl font-semibold text-center">
        {{ i18n.t("Almost done!") }}
      </div>
      <div class="text-lg text-center">
        {{ i18n.t("Register or login") }}
      </div>
    </Signin>
    <div v-else class="container-widget bg-white p-12 flex flex-col gap-7">
      <div class="text-center">
        <icon icon="circle-check" class="text-green-500" />
        <span class="font-bold text-xl">{{
          i18n.t(" Thank you for submitting your story.")
        }}</span>
        <div class="text-xl">
          {{ i18n.t("It will be reviewed by moderators.") }}
        </div>
        <div v-if="!store.user" class="text-lg">
          {{
            i18n.t(
              "Your story will be posted anonymously. Please finish registration to be able to claim/delete it."
            )
          }}
        </div>
      </div>
      <button class="mx-10" @click="$router.back()">{{ i18n.t("Ok") }}</button>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
import { useI18n } from "../../locale";
import Overlay from "../../components/overlay/Overlay.vue";
import Signin from "../../signin/Signin.vue";
import { useGlobalStore } from "../../store";
import { onBeforeRouteLeave } from "vue-router";
import { onMounted, ref } from "vue";

const i18n = useI18n();
const store = useGlobalStore();
const loginHandled = ref(false);

onMounted(() => {
  loginHandled.value = false;
});

onBeforeRouteLeave(() => {
  if (!store.user && !loginHandled.value) {
    loginHandled.value = true;
    return false;
  }
});
</script>

<style scoped></style>
