<template>
  <div
    class="container-widget w-full lg:w-1/3 bg-white p-10 flex flex-col gap-y-5 text-center">
    <div class="text-lg font-bold my-10">
      {{
        i18n.t(
          "Please select at least one passport in order to perform the search."
        )
      }}
    </div>
    <button class="bg-orange-300" @click="emit('select')">
      {{ i18n.t("Select") }}
    </button>
    <button class="bg-gray-300" @click="emit('close')">
      {{ i18n.t("Close") }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "../locale";

const emit = defineEmits<{
  (e: "select"): void;
  (e: "close"): void;
}>();

const i18n = useI18n();
</script>
