<template>
  <div ref="el">
    <div v-if="stories.length > 0">
      <div class="">
        <icon icon="star" class="text-yellow-400" />
        <span>{{
          i18n.t("Read other travelers' stories about this route or")
        }}</span>
        <router-link
          to="/stories/shareStory"
          class="font-bold undecorate text-blue-500">
          {{ i18n.t("share your own") }}</router-link
        >
      </div>
      <div
        class="md:ml-4 border border-gray-300 rounded flex flex-col items-start gap-y-1 py-1 text-base mt-2 font-bold"
        :class="expand ? '' : 'h-[4.2em] relative overflow-hidden'">
        <div class="w-full">
          <div
            v-for="story in stories"
            class="flex flex-row gap-1 items-center leading-7">
            <icon icon="plane" class="text-blue-300" />
            <router-link :to="`/story/${story.id}`" class="undecorate">{{
              story.title
            }}</router-link>
          </div>
          <div
            v-if="expand"
            class="w-full text-center text-blue-500 cursor-pointer"
            @click="expand = false">
            {{ i18n.t("Show less") }}
          </div>
        </div>
        <div
          v-if="!expand"
          class="absolute w-full h-full text-center bottom-0 font-bold text-blue-500 blured flex flex-row items-end justify-center cursor-pointer"
          @click="expand = !expand">
          <span>{{ i18n.t("Show more") }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div>{{ i18n.t("There are no stories yet for this route") }}</div>
        <icon icon="star" class="text-yellow-400" />
        <span class="font-bold">{{ i18n.t(" Be the first one to ") }}</span>
        <router-link
          to="/stories/shareStory"
          class="font-bold undecorate text-blue-500">
          {{ i18n.t("share your experience!") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RelatedStory } from "../../api/client";
import { ref, watch } from "vue";
import { useI18n } from "../../locale";

defineProps<{
  stories: RelatedStory[];
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const i18n = useI18n();

const expand = ref(false);
watch(expand, v => {
  if (!v) emit("close");
});
</script>

<style lang="sass" scoped>
.blured
  background: linear-gradient(transparent, transparent 5%, white 100%)
</style>
