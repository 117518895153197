<template></template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { authSigninManualConfirm } from "../api/client";
import { useGlobalStore } from "../store";
import { httpError } from "../api";

const route = useRoute();
const router = useRouter();
const globalStore = useGlobalStore();

(async () => {
  const resp = await authSigninManualConfirm({
    code: route.hash.slice(1)
  });
  if (resp.status == 200) {
    globalStore.setToken(resp.data);
  } else {
    console.error(httpError(resp));
  }

  window.close();
  await router.replace("/");
})();
</script>

<style scoped></style>
