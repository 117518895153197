<template>
  <div class="relative -translate-x-1/2 -translate-y-full">
    <div
      v-if="showTooltips.value"
      class="absolute bottom-full right-1/2 translate-x-1/2 text-sm bg-white rounded px-1 shadow-xl mb-1 whitespace-nowrap">
      {{ props.loc.name }}, {{ props.loc.cityName }},
      {{ props.loc.countryName }}
    </div>
    <font-awesome-icon
      :class="{
        'text-red-500': props.pos == 'first',
        'text-green-500': props.pos == 'last'
      }"
      class="text-2xl text-blue-500"
      icon="fa-location-dot" />
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Location } from "../../../api/client";
import { Ref } from "vue";

const props = defineProps<{
  loc: Location;
  pos?: "first" | "last";
  showTooltips: Ref<boolean>;
}>();
</script>

<style scoped></style>
